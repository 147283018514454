import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image

const NavTaskBar = ({ userName, username }) => {
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showSalesDropdown, setShowSalesDropdown] = useState(false); // New state for sales dropdown
  const menuRef = useRef(null);

  // Toggle dropdown visibility for the main menu
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Toggle dropdown visibility for the sales report
  const toggleSalesDropdown = () => {
    setShowSalesDropdown(!showSalesDropdown);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
      setShowSalesDropdown(false); // Close sales dropdown
    }
  };

  // Define action handlers for menu items
  const handleAbout = () => {
    navigate('/about'); // Navigate to About page
  };

  const handleProducts = () => {
    console.log("Products action");
  };

  const handlePromotionalPath = () => {
    console.log("Promotional Path action");
  };

  const handleContact = () => {
    console.log("Contact action");
  };

  const handleLogin = () => {
    navigate('/login'); // Navigate to Login page
  };

  const handleSalesPointReport = () => {
    console.log("Sales Point Report action");
  };

  const handleDirectSalesReport = () => {
    console.log("Direct Sales Report action");
  };

  const handleSalesUnitPoints = () => {
    navigate('/userlist', { state: { username } }); // Navigate to User List
  };

  const menuItems = [
    { text: "About", action: handleAbout },
    { text: "Products", action: handleProducts },
    { text: "Promotional Path", action: handlePromotionalPath },
    { text: "Contact", action: handleContact },
    { text: "Login", action: handleLogin }
  ];

  const salesReportItems = [
    { text: "Sales Point Report", action: handleSalesPointReport },
    { text: "Direct Sales Report", action: handleDirectSalesReport },
    { text: "Sales Unit Points", action: handleSalesUnitPoints }
  ];

  return (
    <>
      {/* Navigation Bar */}
      <div style={styles.nav}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '70px', marginRight: '10px' }} />
          <span style={styles.navText}>SUPLEX Ashia</span>
          <span style={{ color: 'teal', fontSize: '1.5em', fontWeight: 'bold', paddingLeft: '10px' }}>
            Information System
          </span>
        </div>
        <p style={{ margin: '0 5px', textAlign: 'center', fontSize: '1.0em', fontWeight: 'bold', color: 'teal' }}>
          Currency: LKR (SL Rupee)
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '20px' }}>
          <label style={{ marginRight: '10px', marginTop: '2px', padding: '12px', backgroundColor: '#20B2AA', color: '#008080', border: 'none', borderRadius: '5px', cursor: 'pointer' }} onClick={() => navigate("/")}>Logout</label>
          <p style={{ margin: '0', color: '#008080', fontSize: '1.0em', fontWeight: 'bold', textAlign: 'right' }}>Welcome</p>
          <p style={{ margin: '0', color: '#008080', fontSize: '1.0em', fontWeight: 'bold' }}>
            {userName || 'Loading..'}!
          </p>
          {username && (
            <p style={{ margin: '0', color: '#008080', fontSize: '1.0em', fontWeight: 'bold' }}>
              ({username})
            </p>
          )}
        </div>
      </div>
      
      {/* Task Bar */}
      <div style={styles.taskBar}>
        <div style={styles.menuContainer} ref={menuRef}>
          <button style={styles.menuButton} onClick={toggleMenu}>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
          </button>
          {showMenu && (
            <div style={styles.menuDropdown}>
              {menuItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    ...styles.menuDropdownItem,
                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#008080' // Dark teal on hover
                  }}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={item.action} // Call the respective action
                >
                  {item.text}
                </button>
              ))}
            </div>
          )}
        </div>

        <div style={styles.salesDropdownContainer} ref={menuRef}>
          <button style={styles.button} onClick={toggleSalesDropdown}>
            Sales Report
          </button>
          {showSalesDropdown && (
            <div style={styles.salesDropdown}>
              {salesReportItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    ...styles.menuDropdownItem,
                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#008080' // Dark teal on hover
                  }}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={item.action} // Call the respective action
                >
                  {item.text}
                </button>
              ))}
            </div>
          )}
        </div>

        <button style={styles.button} onClick={() => navigate("/activities")}>Commision History</button>
        <button style={styles.button} onClick={() => navigate("/registration")}>Registration</button>
        <button style={styles.button} onClick={() => navigate('/userlist', { state: { username } })}>Userlist</button>
      </div>
    </>
  );
};

const styles = {
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFFAFA',
    marginLeft: '60px',
    marginRight: '60px',
    color: 'black',
    height: '120px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '0 20px', // Add horizontal padding
  },
  taskBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '60px',
    marginRight: '60px',
    margin: '1em 0',
    backgroundColor: '#008080',
    padding: '0.5em 20px', // Add horizontal padding
    borderRadius: '8px',
    flexWrap: 'wrap',
    position: 'relative',
  },
  menuContainer: {
    position: 'absolute',
    left: '1em',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButton: {
    width: '50px',
    height: '40px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
    margin: '0',
  },
  menuLine: {
    width: '80%',
    height: '5px',
    background: '#FFFFFF',
    margin: '4px 0',
    borderRadius: '2px',
    transition: '0.3s',
    marginLeft: '20px'
  },
  menuDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    zIndex: 1,
  },
  salesDropdownContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  salesDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    zIndex: 1,
  },
  button: {
    backgroundColor: '#20B2AA',
    color: '#008080',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '12px',
    margin: '0 5px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  },
  menuDropdownItem: {
    display: 'block',
    color: '#FFFFFF',
    textAlign: 'center',
    padding: '10px',
    border: 'none',
    cursor: 'pointer',
    width: '100%', // Make dropdown items full width
  }
};

export default NavTaskBar;
