// Navbar.jsx
import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image

const Navbar = ({ userName, username }) => {
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  // Toggle dropdown visibility
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  // Define action handlers for menu items
  const handleAbout = () => {
    navigate('/about'); // Navigate to About page
  };

  const handleProducts = () => {
    console.log("Products action");
  };

  const handlePromotionalPath = () => {
    console.log("Promotional Path action");
  };

  const handleContact = () => {
    console.log("Contact action");
  };

  const handleLogin = () => {
    navigate('/login'); // Navigate to Login page
  };

  const menuItems = [
    { text: "About", action: handleAbout },
    { text: "Products", action: handleProducts },
    { text: "Promotional Path", action: handlePromotionalPath },
    { text: "Contact", action: handleContact },
    { text: "Login", action: handleLogin }
  ];

  return (
    <>
      {/* Navigation Bar */}
      <div style={styles.nav}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '70px', marginRight: '10px' }} />
          <span style={styles.navText}>SUPLEX Ashia</span>
          <span style={{ color: 'teal', fontSize: '1.5em', fontWeight: 'bold', paddingLeft: '10px' , marginBottom: '25px'}}>
             InformationSystem
          </span>
        </div>
        <p style={{ margin: '0 5px', textAlign: 'center', fontSize: '1.0em', fontWeight: 'bold', color: 'teal' }}>
          Currency: LKR (SL Rupee)
        </p>
       
      </div>
      
      {/* Task Bar */}
      <div style={styles.taskBar}>
        <div style={styles.menuContainer} ref={menuRef}>
          <button style={styles.menuButton} onClick={toggleMenu}>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
          </button>
          {showMenu && (
            <div style={styles.menuDropdown}>
              {menuItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    ...styles.menuDropdownItem,
                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#008080' // Dark teal on hover
                  }}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={item.action} // Call the respective action
                >
                  {item.text}
                </button>
              ))}
            </div>
          )}
        </div>
        <button style={styles.button} onClick={() => navigate("/")}>HOME</button>
        <button style={styles.button} onClick={() => navigate("/products")}>PRODUCTS</button>
        <button style={styles.button} onClick={() => navigate("")}>ACTIVITIES</button>
        <button style={styles.button} onClick={() => navigate("/buynow")}>BUY NOW</button>
        <button style={styles.button} onClick={() => navigate("/contact")}>CONTACT</button>
      </div>
    </>
  );
};

const styles = {
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '16px',
    marginLeft: '60px',
    marginRight: '60px',
    color: 'black',
    height: '120px',
    padding: '0 20px', // Fixed horizontal padding
    width: '1450px', // Fixed width for non-responsiveness
  },
  taskBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1em 60px', // Consistent margin
    backgroundColor: '#008080',
    padding: '0.5em 20px',
    borderRadius: '16px',
    width: '1450px', // Fixed width for non-responsiveness
    position: 'relative',
  },
  menuContainer: {
    position: 'absolute',
    left: '1em',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButton: {
    width: '50px',
    height: '40px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
    margin: '0',
  },
  menuLine: {
    width: '80%',
    height: '5px',
    background: '#FFFFFF',
    margin: '4px 0',
    borderRadius: '2px',
    transition: '0.3s',
  },
  menuDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    color: '#FFFFFF',
    borderRadius: '5px',
    zIndex: 9,
    width: '200px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  menuDropdownItem: {
    width: '100%',
    padding: '10px 15px',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    transition: '0.3s',
  },
  salesDropdownContainer: {
    position: 'relative',
    zIndex: 10,
  },
  salesDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    color: '#FFFFFF',
    borderRadius: '5px',
    zIndex: 9,
    width: '200px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  specialDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    color: '#FFFFFF',
    borderRadius: '5px',
    zIndex: 9,
    width: '200px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  button: {
    padding: '0.5em 1.5em',
    backgroundColor: '#E0FFFF',
    color: '#008080',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    margin: '0.5em',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '50px',
    fontSize: '0.9em',
    textAlign: 'center',
  },
  navText: {
    color: '#008080',
    fontSize: '1.5em',
    marginBottom: '26px',
    fontWeight: 'bold',
  },
};

export default Navbar;
