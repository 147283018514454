import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

const SendMail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { newUsername, newPassword, email } = location.state || {};

    // Static sender name
    const senderName = 'SuplexAshia PVT (LTD)';

    // Prepare parameters for EmailJS
    const params = {
        sendername: senderName,
        to: email,
        subject: 'Account Creation Confirmation',
        replyto: email,
        message: `
            Dear User,

            Your account has been created successfully.

            Your UserID: ${newUsername}
            Password: ${newPassword}

            If you have any questions, please contact us.

            Best regards,
            SuplexAshia PVT (LTD)
        `,
    };

    const handleSendEmail = (e) => {
        e.preventDefault();

        // Send email using EmailJS
        emailjs.send('service_qo8iv3h', 'template_hun8ve9', params, 'aMFNkxNYcqJS3kabm')
            .then((response) => {
                console.log('Email sent successfully:', response);
                alert('Email sent successfully!');
            })
            .catch((error) => {
                console.error('Error sending email:', error);
                alert('Error sending email.');
            });
    };

    const handleCancel = () => {
        navigate('/registration');
    };

    const imagePath = './bancsuplex.jpg'; // Update with the correct image path

    // New styles
    const styles = {
        wrapper: {
            fontFamily: 'Arial, sans-serif',
            margin: 0,
            padding: 0,
            boxSizing: 'border-box',
            position: 'relative',
            minHeight: '100vh',
            backgroundColor: '#E0FFFF',
            width: '1620px', // Fixed width for non-responsiveness
        },
        imageContainer: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '300px',
            background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`,
            backgroundSize: 'cover',
        },
        container: {
            padding: '20px',
            backgroundColor: 'rgba(224, 255, 255, 0.7)',
            borderRadius: '12px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            margin: '20px auto',
            maxWidth: '900px',
            position: 'relative',
            zIndex: 1,
        },
        textarea: {
            width: '100%',
            padding: '8px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            backgroundColor: '#E0FFFF', // Light teal color
            height: '200px', // Fixed height for the message box
            resize: 'none', // Prevent resizing
        },
    };

    return (
        <div style={styles.wrapper}>
            <div style={styles.imageContainer}></div>
            <div style={styles.container}>
                <h1 className="title">Send Email</h1>
                <form onSubmit={handleSendEmail} className="form">
                    <div className="form-group">
                        <label>Sender Name:</label>
                        <input
                            type="text"
                            value={senderName}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label>To (Email):</label>
                        <input
                            type="text"
                            value={email || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label>SRI User ID:</label>
                        <input
                            type="text"
                            value={newUsername || ''}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label>Message:</label>
                        <textarea
                            style={styles.textarea}
                            cols="40"
                            rows="8"
                            value={`
                                Dear User,

                                Your account has been created successfully.

                                Placement Top: ${newUsername}
                                Password: ${newPassword}

                                If you have any questions, please contact us.

                                Best regards,
                                SuplexAshiaWebsite
                            `}
                            readOnly
                        ></textarea>
                    </div>
                    <div className="button-group">
                        <button type="submit">Send</button>
                        <button type="button" onClick={handleCancel} className="cancel-button">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SendMail;
