import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { Helmet } from 'react-helmet'; // Import Helmet
import imagePath from './bancsuplex.jpg'; // Update the image path accordingly
import Navbar from './Navbar'; // Import Navbar component

const database = getDatabase();

function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const userRef = ref(database, 'users/');
            const snapshot = await get(userRef);

            if (snapshot.exists()) {
                const users = snapshot.val();
                const user = Object.values(users).find(user => user.username === username);

                if (user) {
                    if (user.password === password) {
                        navigate('/personaldash', { state: { username: username } });
                    } else {
                        setError('Incorrect password.');
                    }
                } else {
                    setError('Username not found.');
                }
            } else {
                setError('No users found.');
            }
        } catch (error) {
            console.error('Error during login:', error);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div style={styles.wrapper}>
            <Helmet>
                <title>SuplexashiaLogin</title>
                <meta name="description" content="Login to manage your team and access your personalized dashboard." />
                <meta name="keywords" content="suplexashialogin, suplexlogin, Suplexashia login" />
            </Helmet>
            
            <Navbar /> 
            <div style={styles.mainContent}>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.formGroup}>
                        <label htmlFor="username" style={styles.label}>User ID:</label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            style={styles.input}
                            placeholder=""
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label htmlFor="password" style={styles.label}>Password:</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={styles.input}
                            placeholder=""
                        />
                    </div>
                    {error && <p style={styles.error}>{error}</p>}
                    <div style={styles.formGroup}>
                        <button type="submit" style={styles.button}>Login</button>
                        <button type="button" style={styles.button} onClick={() => { setUsername(''); setPassword(''); }}>Reset</button>
                    </div>
                </form>
            </div>
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    © 2024 Suplex Ashia. All rights reserved.
                </div>
            </footer>
            <div style={styles.imageContainer} />
        </div>
    );
}

const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh', // Keeps the wrapper at least the full view height
        height: '1000px', // Allow the content to determine height
        background: '#E0FFFF', // Light teal background color
        width: '1620px', // Fixed width for non-responsiveness
    },
    
    imageContainer: {
        width: '100%', // Full width of the wrapper
        height: '300px', // Fixed height for the image
        position: 'absolute', // Position it at the bottom of wrapper
        bottom: 0, // Align to the bottom
        left: 0, // Align to the left
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`, // Add a gradient overlay
        backgroundSize: 'cover', // Cover the container
        //zIndex: 1, // Place behind other content
    },
    mainContent: {
        display: 'flex',
        justifyContent: 'center', // Center the content horizontally
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: '350px', // Space for the footer and image
        position: 'relative',
        zIndex: 2, // Ensure form is above the image
        width: '100%', // Full width to maintain centering
    },
    form: {
        padding: '20px',
        width: '40%',
        backgroundColor: 'rgba(224, 255, 255, 0.7)',
        borderRadius: '25px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        margin: '20px auto',
        maxWidth: '1000px',
        position: 'relative',
    },
    formGroup: {
        marginBottom: '20px',
    },
    label: {
        marginBottom: '5px',
        fontWeight: 'bold',
        color: 'teal',
    },
    input: {
        width: '30%',
        padding: '4px',
        borderRadius: '4px',
        border: '1px solid #008080',
        backgroundColor: '#E0FFFF',
        fontSize: '16px',
    },
    button: {
        margin: '6px',
        padding: '7px',
        backgroundColor: 'teal',
        color: '#E0FFFF',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '5px',
        width: '20%', // Full width button for consistency
    },
    error: {
        color: 'red',
        marginTop: '10px',
        textAlign: 'center',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
    },
    heading: {
        fontSize: '1.5em',
        textAlign: 'center',
        color: 'teal',
    },
};

export default Login;
