import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getDatabase, ref, onValue, update } from 'firebase/database';
import NavTaskBar from './NavTaskBar';
import imagePath from './bancsuplex.jpg'; // Update the image path accordingly

const UpdateInfo = (props) => {
    const {
        handleAbout,
        handleProducts,
        handlePromotionalPath,
        handleContact,
        handleLogin,
        dropdownItems,
        openDropdown,
        setOpenDropdown,
        hoverIndex,
        setHoverIndex,
        showMenu,
        setShowMenu,
        dropdownRef,
        menuRef,
        navigate,
    } = props;

    const location = useLocation();
    const { username } = location.state || {};
    const [userData, setUserData] = useState({});
    const [fathersName, setFathersName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dob, setDob] = useState('');
    const [beneficiary, setBeneficiary] = useState({ name: '', dob: '', relationship: 'Father' });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Fetch user data from Firebase
    useEffect(() => {
        if (username) {
            const db = getDatabase();
            const userRef = ref(db, `users/${username}`);
            onValue(userRef, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    setUserData(data);
                    setFathersName(data.fathersName || '');
                    setPhoneNumber(data.phone || '');
                    setDob(data.dob || '');
                    // Set additional user fields
                    setBeneficiary({ name: data.beneficiaries?.name || '', dob: data.beneficiaries?.dob || '', relationship: 'Father' });
                }
            }, (error) => {
                console.error('Error fetching data from Firebase:', error);
                setError('Failed to load user data');
            });
        }
    }, [username]);

    const handleUpdate = async (e) => {
        e.preventDefault();
        const db = getDatabase();
        const userRef = ref(db, `users/${username}`);
        try {
            await update(userRef, {
                fathersName,
                phone: phoneNumber,
                dob,
                beneficiaries: {
                    name: beneficiary.name,
                    dob: beneficiary.dob,
                    relationship: beneficiary.relationship,
                },
            });
            setSuccess('Information updated successfully!');
        } catch (err) {
            setError('Failed to update information');
        }
    };

    const resetForm = () => {
        setFathersName(userData.fathersName || '');
        setPhoneNumber(userData.phone || '');
        setDob(userData.dob || '');
        setBeneficiary({ name: '', dob: '', relationship: 'Father' });
        setError('');
        setSuccess('');
    };

    return (
        <div style={styles.wrapper}>
            <NavTaskBar
                username={username}
                userName={userData.name}
                handleAbout={handleAbout}
                handleProducts={handleProducts}
                handlePromotionalPath={handlePromotionalPath}
                handleContact={handleContact}
                handleLogin={handleLogin}
                dropdownItems={dropdownItems}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                hoverIndex={hoverIndex}
                setHoverIndex={setHoverIndex}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                dropdownRef={dropdownRef}
                menuRef={menuRef}
                navigate={navigate}
            />
            <div style={styles.container}>
                <form onSubmit={handleUpdate} style={styles.form}>
                    <h1 style={styles.subHeader}>User Information</h1>
                    <div style={styles.row}>
                        {/* Left side - User ID and Beneficiary Details */}
                        <div style={styles.leftColumn}>
                        <h3 style={styles.subHeader}>User Details</h3>
                        <div style={styles.branchContainer}>
                            <p><strong>User ID:</strong> {username}</p>
                            <p><strong>Name:</strong> {userData.name || 'N/A'}</p>
                            <p><strong>NIC:</strong> {userData.nic || 'N/A'}</p>
                            <p><strong>Address:</strong> {userData.address || 'N/A'}</p>
                            <p><strong>Email:</strong> {userData.email || 'N/A'}</p>
                            <p><strong>Telephone:</strong> {userData.phone || 'N/A'}</p>
                        </div>


                            {/* Beneficiary Details */}
                            <h3 style={styles.subHeader}>Beneficiary Details</h3>
                            <div style={styles.bankContainer}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Beneficiary Name</label>
                                <input
                                    type="text"
                                    value={beneficiary.name}
                                    onChange={(e) => setBeneficiary({ ...beneficiary, name: e.target.value })}
                                    required
                                    style={styles.input}
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Beneficiary Date of Birth</label>
                                <input
                                    type="date"
                                    value={beneficiary.dob}
                                    onChange={(e) => setBeneficiary({ ...beneficiary, dob: e.target.value })}
                                    required
                                    style={styles.input}
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Relationship</label>
                                <select
                                    value={beneficiary.relationship}
                                    onChange={(e) => setBeneficiary({ ...beneficiary, relationship: e.target.value })}
                                    style={styles.input}
                                >
                                    <option value="Father">Father</option>
                                    <option value="Mother">Mother</option>
                                    <option value="Sister">Sister</option>
                                    <option value="Brother">Brother</option>
                                </select>
                            </div>
                            </div>
                        </div>

                        {/* Right side - Dealer Point and Bank Details */}
                        <div style={styles.rightColumn}>
                            {/* Dealer Point */}
                            <h3 style={styles.subHeader}>Dealer Point</h3>
                            <div style={styles.branchContainer}>
                            <p><strong>Branch Code:</strong> {userData.branchCode || 'N/A'}</p>
                                <p><strong>Branch Contact:</strong> {userData.branchContact || 'N/A'}</p>
                                <p><strong>Branch Name:</strong> {userData.branchName || 'N/A'}</p>
                                <p><strong>Branch Address:</strong> {userData.branchAddress || 'N/A'}</p>
                                <p><strong>City:</strong> {userData.city || 'N/A'}</p>
                                <p><strong>District:</strong> {userData.district || 'N/A'}</p>
                            </div>

                            {/* Bank Details */}
                            <h3 style={styles.subHeader}>Bank Details</h3>
                            <div style={styles.bankContainer}>
                                <p><strong>Account Holder Name:</strong> {userData.accountHolderName || 'N/A'}</p>
                                <p><strong>Account Number:</strong> {userData.accountNumber || 'N/A'}</p>
                                <p><strong>Account Type:</strong> {userData.accountType || 'N/A'}</p>
                                <p><strong>Bank:</strong> {userData.bank || 'N/A'}</p>
                                <p><strong>Branch:</strong> {userData.branch || 'N/A'}</p>
                            </div>
                        </div>
                    </div>

                    {/* Update User Information */}
                    <div style={styles.bankContainer}>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Date of Birth</label>
                            <input
                                type="date"
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                                required
                                style={styles.input}
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Father's Name</label>
                            <input
                                type="text"
                                value={fathersName}
                                onChange={(e) => setFathersName(e.target.value)}
                                required
                                style={styles.input}
                            />
                        </div>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Phone Number</label>
                            <input
                                type="text"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                                style={styles.input}
                            />
                        </div>
                    </div>
                    {error && <div style={styles.error}>{error}</div>}
                    {success && <div style={styles.success}>{success}</div>}
                    <div style={styles.buttonContainer}>
                        <button type="submit" style={styles.button}>Update</button>
                        <button type="button" onClick={resetForm} style={styles.button}>Reset</button>
                    </div>
                </form>
            </div>
            <div style={styles.imageContainer} />
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Ashia. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh', // Keeps the wrapper at least the full view height
        height: 'auto', // Allow the content to determine height
        background: '#E0FFFF', // Light teal background color
        width: '1620px', // Fixed width for non-responsiveness
    },
    
    imageContainer: {
        width: '100%', // Full width of the viewport
        height: '300px', // Fixed height for the image
        //position: 'absolute', // Position it absolutely
        bottom: 0, // Align to the bottom
        left: 0, // Align to the left
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`, // Add a gradient overlay
        backgroundSize: 'cover', // Ensure the image covers the container
        zIndex: -1, // Place it behind other content
    },
    container: {
        padding: '20px',
        backgroundColor: 'rgba(224, 255, 255, 0.7)',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        margin: '20px auto',
        maxWidth: '900px',
        position: 'relative',
        zIndex: 1,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px',
    },
    leftColumn: {
        width: '45%',
    },
    rightColumn: {
        width: '45%',
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
    },
    label: {
        fontWeight: 'bold',
        color: '#333',
    },
    input: {
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    },
    subHeader: {
        marginBottom: '15px',
        color: '#008080',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '20px',
    },
    button: {
        backgroundColor: '#008080',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: 'pointer',
        textAlign: 'center',
    },
    
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        padding: '20px 0',
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    footerContainer: {
        textAlign: 'center',
        maxWidth: '1000px',
        margin: '0 auto',
    },
    branchContainer: {
        padding: '10px',
        backgroundColor: '#B2E0D9', // Light teal background color
        borderRadius: '10px',
        marginBottom: '15px',
    },
    bankContainer: {
        padding: '10px',
        backgroundColor: '#B2E0D9', // Light teal background color
        borderRadius: '10px',
    },
    error: {
        color: 'red',
        fontWeight: 'bold',
    },
    success: {
        color: 'green',
        fontWeight: 'bold',
    },
};

export default UpdateInfo;
