import React, { useEffect } from 'react';

// Higher-Order Component to persist zoom level
const ZoomPersist = ({ children }) => {
  useEffect(() => {
    // On load, retrieve the zoom level from localStorage and apply it
    const storedZoom = localStorage.getItem('zoomLevel') || '100%';
    document.body.style.zoom = storedZoom;

    // Before unload, store the current zoom level
    const handleBeforeUnload = () => {
      localStorage.setItem('zoomLevel', document.body.style.zoom);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return <>{children}</>; // Render the children wrapped by this HOC
};

export default ZoomPersist;
