import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image
import cosmetic1 from './cosmetic1.jpg';
import cosmetic2 from './cosmetic2.jpg';
import cosmetic3 from './cosmetic3.jpg';
import cosmetic4 from './cosmetic4.jpg';
import cosmetic5 from './cosmetic5.jpg';
import Navbar from './Navbar'; // Import Navbar component
import imagePath from './bancsuplex.jpg'; // Update the image path accordingly

function Products() {
    const [openDropdown, setOpenDropdown] = useState(null);
    const [currentReviewIndex, setCurrentReviewIndex] = useState(0);
    const navigate = useNavigate();

    const handleDropdownClick = (index) => setOpenDropdown(openDropdown === index ? null : index);

    const menuItems = [
        { text: 'About', action: () => navigate("/about") },
        { text: 'Products', action: () => navigate("/products") },
        { text: 'Activities', action: () => setOpenDropdown(null) },
        { text: 'Buy Now', action: () => setOpenDropdown(null) }
    ];

    // Customer reviews
    const reviews = [
        { name: "Nimal Perera", comment: "Excellent products, I loved them!" },
        { name: "Kumara Silva", comment: "The best cosmetics I've ever used!" },
        { name: "Anjali Fernando", comment: "Very satisfied with the quality!" },
        { name: "Shanika De Silva", comment: "Highly recommend this brand!" },
        { name: "Ravi Jayasuriya", comment: "Great value for money!" },
        { name: "Latha Perera", comment: "Wonderful experience shopping here!" },
        { name: "Saman Kumara", comment: "Fast delivery and great service!" },
        { name: "Dhanushka Fernando", comment: "My skin feels amazing!" },
        { name: "Gamini Rajapaksha", comment: "Love the variety of products!" },
        { name: "Piumi Jayasinghe", comment: "Best cosmetics I've tried!" },
        { name: "Aasiri Madushan", comment: "Always a great shopping experience!" },
        { name: "Tharindu Liyanage", comment: "Top-notch quality and service!" },
        { name: "Kavisha De Silva", comment: "Never going back to my old brand!" },
        { name: "Chathura Perera", comment: "So happy with my purchase!" },
        { name: "Roshini Fernando", comment: "Absolutely love the packaging!" },
    ];

    const products = [
        { id: 1, name: "Cosmetic 1", description: "A premium cosmetic product that enhances your beauty.", image: cosmetic1, rating: 5 },
        { id: 2, name: "Cosmetic 2", description: "High-quality cosmetic with nourishing ingredients.", image: cosmetic2, rating: 4 },
        { id: 3, name: "Cosmetic 3", description: "The perfect choice for a glowing complexion.", image: cosmetic3, rating: 3 },
        { id: 4, name: "Cosmetic 4", description: "Luxurious and effective, designed for all skin types.", image: cosmetic4, rating: 4 },
        { id: 5, name: "Cosmetic 5", description: "Revitalize your skin with this exquisite cosmetic product.", image: cosmetic5, rating: 5 },
    ];

    const handleAddToCart = (productName) => {
        alert(`${productName} has been added to your cart!`);
    };

    // Function to handle review navigation
    const nextReview = () => {
        setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    return (
        <div style={styles.wrapper}>
            <Navbar /> {/* Include Navbar here */}

            <div style={styles.container}>
                <header style={styles.header}>
                    <h1 style={styles.heading}>Our Products</h1>
                </header>

                {/* Customer Review Display */}
                <div style={styles.reviewSection}>
                    <h2 style={styles.reviewHeading}>Customer Review</h2>
                    <div style={styles.review}>
                        <p style={styles.reviewName}>{reviews[currentReviewIndex].name}</p>
                        <p style={styles.reviewComment}>{reviews[currentReviewIndex].comment}</p>
                    </div>
                    <button onClick={nextReview} style={styles.nextReviewButton}>Next Review</button>
                </div>

                <div style={styles.grid}>
                    {products.map((product) => (
                        <div key={product.id} style={styles.item}>
                            <img src={product.image} alt={product.name} style={styles.image} />
                            <div style={styles.details}>
                                <h2 style={styles.productName}>{product.name}</h2>
                                <p style={styles.productDescription}>{product.description}</p>
                                <div style={styles.rating}>
                                    {Array.from({ length: product.rating }, (_, index) => (
                                        <span key={index} style={styles.star}>⭐</span>
                                    ))}
                                    {Array.from({ length: 5 - product.rating }, (_, index) => (
                                        <span key={index} style={styles.starEmpty}>⭐</span>
                                    ))}
                                </div>
                                <label onClick={() => handleAddToCart(product.name)} style={styles.addToCartButton}>Add to Cart</label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    © 2024 Suplex Ashia. All rights reserved.
                </div>
            </footer>
        </div>
    );
}

const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh',
        background: `linear-gradient(to bottom, #E0FFFF 30%, transparent), url(${imagePath})`, // Light teal on top, transparent at the bottom
        backgroundPosition: 'bottom', // Position the background image at the bottom
        backgroundSize: 'cover', // Cover the full container
        backgroundRepeat: 'no-repeat',
        height: '100%', // Use full viewport height
        width: '1620px', // Fixed width for non-responsiveness
    },
    container: {
        padding: '20px',
        backgroundColor: 'rgba(224, 255, 255, 0.8)', // Slightly more opaque
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        margin: '20px auto',
        maxWidth: '900px',
        position: 'relative',
        zIndex: 1,
    },
    header: {
        textAlign: 'center',
        margin: '2em 0',
    },
    heading: {
        fontSize: '2.5em',
        color: '#333',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '20px',
        padding: '0 20px',
    },
    item: {
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        padding: '2em',
        textAlign: 'center',
        maxWidth: '400px',
        margin: '1em auto',
        transition: 'transform 0.2s', // Animation for scale
    },
    image: {
        width: '100%',
        maxWidth: '200px',
        height: 'auto',
        borderRadius: '8px',
    },
    details: {
        marginTop: '10px',
    },
    productName: {
        fontSize: '1.5em',
        color: '#008080',
    },
    productDescription: {
        fontSize: '1em',
        color: '#555',
    },
    rating: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0',
    },
    star: {
        color: '#FFD700',
        fontSize: '1.5em',
    },
    starEmpty: {
        color: '#ccc',
        fontSize: '1.5em',
    },
    addToCartButton: {
        backgroundColor: '#008080',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        cursor: 'pointer',
        marginTop: '10px',
        transition: 'background-color 0.3s',
    },
    reviewSection: {
        padding: '20px',
        backgroundColor: 'rgba(224, 255, 255, 0.8)', // Adjust transparency for better visibility
        borderRadius: '8px',
        margin: '20px auto',
        maxWidth: '900px',
    },
    reviewHeading: {
        fontSize: '1.5em',
        textAlign: 'center',
        color: '#333',
    },
    review: {
        margin: '1em 0',
        textAlign: 'center',
    },
    reviewName: {
        fontWeight: 'bold',
        fontSize: '1.2em',
    },
    reviewComment: {
        fontStyle: 'italic',
    },
    nextReviewButton: {
        backgroundColor: '#008080',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        cursor: 'pointer',
        marginTop: '10px',
        transition: 'background-color 0.3s',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1,
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
    },
};

export default Products;
