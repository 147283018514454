import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getDatabase, ref, update, get, onValue } from 'firebase/database';
import NavTaskBar from './NavTaskBar';
import imagePath from './bancsuplex.jpg';

const ChangePassword = (props) => {
    const { handleAbout, handleProducts, handlePromotionalPath, handleContact, handleLogin, dropdownItems, openDropdown, setOpenDropdown, hoverIndex, setHoverIndex, showMenu, setShowMenu, dropdownRef, menuRef, navigate } = props;
    const location = useLocation();
    const { username } = location.state || {};
    const [userName, setUserName] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    // Fetch and set the userName from Firebase
    useEffect(() => {
        console.log('PlacementTop received:', username); // Debugging
        if (username) {
            const db = getDatabase();
            const usersRef = ref(db, 'users');
            onValue(usersRef, (snapshot) => {
                const users = snapshot.val();
                console.log('Fetched users:', users); // Debugging
                if (users) {
                    const user = Object.values(users).find(user => user.username === username);
                    if (user) {
                        console.log('User found:', user, username); // Debugging
                        setUserName(user.name);
                    } else {
                        console.log('No user found with placementTop:', username);
                        setUserName('User not found');
                    }
                } else {
                    console.log('No users data available in Firebase.');
                    setUserName('No user data available');
                }
            }, (error) => {
                console.error('Error fetching data from Firebase:', error);
                setUserName('Error loading user');
            });
        }
    }, [username]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the new passwords match
        if (newPassword !== confirmPassword) {
            setError('New passwords do not match');
            return;
        }

        const db = getDatabase();
        const usersRef = ref(db, 'users');

        try {
            // Fetch current user data to validate old password
            const snapshot = await get(usersRef);
            const users = snapshot.val();
            const user = Object.values(users).find(user => user.username === username);

            if (user) {
                if (user.password === oldPassword) {
                    const userRef = ref(db, `users/${user.username}`); // Ensure this is the correct key
                    await update(userRef, { password: newPassword });
                    setSuccess('Password updated successfully!');
                    resetForm();
                } else {
                    setError('Old password is incorrect');
                }
            } else {
                setError('User not found');
            }
        } catch (err) {
            console.error('Failed to update password:', err);
            setError('Failed to update password');
        }
    };

    const resetForm = () => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setError('');
        setSuccess('');
    };

    return (
        <div style={styles.wrapper}>
            <NavTaskBar 
                username={username} 
                userName={userName}
                handleAbout={handleAbout}
                handleProducts={handleProducts}
                handlePromotionalPath={handlePromotionalPath}
                handleContact={handleContact}
                handleLogin={handleLogin}
                dropdownItems={dropdownItems}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                hoverIndex={hoverIndex}
                setHoverIndex={setHoverIndex}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                dropdownRef={dropdownRef}
                menuRef={menuRef}
                navigate={navigate}
            />
            <div style={styles.container}>
                <h2 style={styles.header}>Change Password</h2>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>User ID</label>
                        <input type="text" value={username} readOnly style={styles.input} />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Old Password</label>
                        <input
                            type="password"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            required
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>New Password</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Confirm New Password</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            style={styles.input}
                        />
                    </div>
                    {error && <div style={styles.error}>{error}</div>}
                    {success && <div style={styles.success}>{success}</div>}
                    <div style={styles.buttonContainer}>
                        <button type="submit" style={styles.button}>Change Password</button>
                        <button type="button" onClick={resetForm} style={styles.resetButton}>Reset</button>
                    </div>
                </form>
            </div>
            <div style={styles.imageContainer} />
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Ashia. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};


const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh', // Keeps the wrapper at least the full view height
        height: 'auto', // Allow the content to determine height
        background: '#E0FFFF', // Light teal background color
        width: '1620px', // Fixed width for non-responsiveness
    },
    
    imageContainer: {
        width: '100%', // Full width of the viewport
        height: '300px', // Fixed height for the image
        //position: 'absolute', // Position it absolutely
        bottom: 0, // Align to the bottom
        left: 0, // Align to the left
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`, // Add a gradient overlay
        backgroundSize: 'cover', // Ensure the image covers the container
        zIndex: -1, // Place it behind other content
    },
    container: {
        padding: '20px',
        backgroundColor: 'rgba(224, 255, 255, 0.7)',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        margin: '20px auto',
        maxWidth: '600px', // Reduced max width
        position: 'relative',
        zIndex: 1,
    },
    header: {
        fontSize: '1.5em',
        textAlign: 'center',
        color: 'teal',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        width: '50%', // Make it responsive
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        marginBottom: '5px',
        fontWeight: 'bold',
        color: 'teal',
    },
    input: {
        padding: '8px', // Reduced padding for smaller size
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '90%',
    },
    button: {
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#008080',
        color: '#fff',
        fontWeight: 'bold',
        cursor: 'pointer',
        width: '150px', // Set a fixed width for the button
        marginRight: '10px', // Add margin for spacing
    },
    resetButton: {
        padding: '10px',
        borderRadius: '5px',
        border: 'none',
        backgroundColor: '#008080', // Red background for reset
        color: '#fff',
        width: '100px', // Set a fixed width for the button
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
    success: {
        color: 'green',
        textAlign: 'center',
    },
    footer: {
        backgroundColor: '#008080',
        color: 'white',
        padding: '10px 0',
        textAlign: 'center',
        position: 'relative',
        width: '100%',
        bottom: '0',
        zIndex: 1,
    },
    footerContainer: {
        maxWidth: '900px',
        margin: '0 auto',
        
    },
};

export default ChangePassword;
