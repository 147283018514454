import React, { useState, useEffect } from 'react';
import { database, ref, get, child } from './firebase';

const ParentComponent = () => {
    const [presenters, setPresenters] = useState([]);
    const [selectedPresenter, setSelectedPresenter] = useState('');

    // Function to fetch presenters from the database (adminConfig node)
    useEffect(() => {
        const fetchPresenters = async () => {
            try {
                const snapshot = await get(child(ref(database), 'adminConfig/presenters'));
                if (snapshot.exists()) {
                    const presentersData = snapshot.val();
                    setPresenters(presentersData || []); // Set presenters from adminConfig
                }
            } catch (error) {
                console.error('Error fetching presenters:', error);
            }
        };

        fetchPresenters();
    }, []);

    // Handle dropdown selection change
    const handleDropdownChange = (e) => {
        setSelectedPresenter(e.target.value);
    };

    return (
        <div>
            {/* Render the dropdown with the fetched presenters */}
            <select name="presenter" value={selectedPresenter} onChange={handleDropdownChange}>
                <option value="" disabled>Select a presenter</option>
                {presenters.map((presenter, index) => (
                    <option key={index} value={presenter}>
                        {presenter}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default ParentComponent;
