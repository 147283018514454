import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image
import backgroundImage from './bancsuplex.jpg'; // Import the background image
import { Helmet } from 'react-helmet'; // Import Helmet
import cosmetic1 from './cosmetic1.jpg'; // Import the first cosmetic image
import cosmetic2 from './cosmetic2.jpg'; // Import the second cosmetic image
import cosmetic3 from './cosmetic3.jpg'; // Import the third cosmetic image
import cosmetic4 from './cosmetic4.jpg'; // Import the fourth cosmetic image
import cosmetic5 from './cosmetic5.jpg'; // Import the fifth cosmetic image

function Dashboard() {
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [showMenu, setShowMenu] = useState(false); // State for the hamburger menu
  const menuRef = useRef(null); // Ref for the menu

  // Toggle dropdown visibility
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  // Define action handlers for menu items
  const handleAbout = () => {
    navigate('/about'); // Navigate to About page
  };

  const handleProducts = () => {
    console.log("Products action");
  };

  const handlePromotionalPath = () => {
    console.log("Promotional Path action");
  };

  const handleContact = () => {
    console.log("Contact action");
  };

  const handleLogin = () => {
    navigate('/login'); // Navigate to Login page
  };

  const menuItems = [
    { text: "About", action: handleAbout },
    { text: "Products", action: handleProducts },
    { text: "Promotional Path", action: handlePromotionalPath },
    { text: "Contact", action: handleContact },
    { text: "Login", action: handleLogin }
  ];

  return (
    <div style={styles.wrapper}>
      <Helmet>
                <title>Dashboard</title>
                <meta name="description" content="Login to manage your team and access your personalized dashboard." />
                <meta name="keywords" content="suplexashialogin, suplexlogin, Suplexashia login" />
            </Helmet>
      {/* Background Container */}
      <div style={styles.background} />

      {/* Navigation Bar */}
      <div style={styles.nav}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '150px', marginRight: '10px' }} />
          <span style={styles.navText}>SUPLEX Ashia</span> {/* Text with common teal */}
          <span style={{ color: 'teal', fontSize: '1.5em', fontWeight: 'bold', alignItems: 'center', paddingLeft: '10px' }}>Information System</span> {/* Text with common teal */}
        </div>
      </div>

      {/* Task Bar */}
      <div style={styles.taskBar}>
        <div style={styles.menuContainer} ref={menuRef}>
          <button style={styles.menuButton} onClick={toggleMenu}>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
          </button>
          {showMenu && (
            <div style={styles.menuDropdown}>
              {menuItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    ...styles.menuDropdownItem,
                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#008080' // Dark teal on hover
                  }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={item.action} // Call the respective action
                >
                  {item.text}
                </button>
              ))}
            </div>
          )}
        </div>
        <button style={styles.button} onClick={() => navigate("/")}>HOME</button> {/* Navigate to Home page */}
        <button style={styles.button} onClick={() => navigate("/products")}>PRODUCTS</button>
        <button style={styles.button} onClick={() => navigate("")}>ACTIVITIES</button>
        <button style={styles.button} onClick={() => navigate("/buynow")}>BUY NOW</button>
        <button style={styles.button} onClick={() => navigate("/contact")}>CONTACT</button>
      </div>

      {/* Images Section */}
      <div style={styles.imageSection}>
        <div style={styles.imageWrapper}>
          <img src={cosmetic1} alt="Cosmetic 1" style={styles.image} />
          <img src={cosmetic2} alt="Cosmetic 2" style={styles.image} />
          <img src={cosmetic3} alt="Cosmetic 3" style={styles.image} />
          <img src={cosmetic4} alt="Cosmetic 4" style={styles.image} />
          <img src={cosmetic5} alt="Cosmetic 5" style={styles.image} />
        </div>
      </div>

      {/* Image Container with Gradient Overlay */}
      <div style={styles.imageContainer} />

      {/* Footer */}
      <footer style={styles.footer}>
        <div style={styles.footerContainer}>
          <p>© 2024 Suplex Ashia. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

const styles = {
  wrapper: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    position: 'relative',
    minHeight: '100vh', // Keeps the wrapper at least the full view height
    height: '1000px', // Allow the content to determine height
    background: '#E0FFFF', // Light teal background color
    width: '1630px', // Fixed width for non-responsiveness
},

imageContainer: {
  width: '100%', // Full width of the wrapper
  height: '300px', // Fixed height for the image
  position: 'absolute', // Position it at the bottom of wrapper
  bottom: 0, // Align to the bottom
  left: 0, // Align to the left
  background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${backgroundImage}) no-repeat center`, // Add a gradient overlay
  backgroundSize: 'cover', // Cover the container
  //zIndex: 1, // Place behind other content
},
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFFAFA',
    marginLeft: '60px',
    marginRight: '60px',
    color: 'black',
    height: '120px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    padding: '0 20px', // Add horizontal padding
  },
  taskBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1em 60px', // Apply consistent margin with left and right included
    backgroundColor: '#008080',
    padding: '0.5em 20px',
    borderRadius: '8px',
    flexWrap: 'wrap',
    position: 'relative',
  },
  menuContainer: {
    position: 'absolute',
    left: '1em',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButton: {
    width: '50px',
    height: '40px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
    margin: '0',
  },
  imageSection: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
    height: '250px',
  },
  imageWrapper: {
    display: 'flex',
    animation: 'scroll 10s linear infinite',
    height: '100%',
  },
  image: {
    flexShrink: 0,
    width: '100%',
    height: '2000px',
    objectFit: 'cover',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  footer: {
    backgroundColor: '#008080',
    color: '#fff',
    textAlign: 'center',
    padding: '10px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
},
footerContainer: {
    margin: '0 auto',
    width: '80%',
},
  menuDropdown: {
    position: 'absolute',
    top: '100%',
    left: 0,
    backgroundColor: '#008080',
    borderRadius: '4px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1,
  },
  menuDropdownItem: {
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    width: '100%', // Ensure full width for dropdown items
  },
  menuLine: {
    width: '80%',
    height: '5px',
    background: '#FFFFFF',
    margin: '4px 0',
    borderRadius: '2px',
    transition: '0.3s',
    marginLeft: '20px'
    },
  button: {
padding: '0.5em 1.5em',
backgroundColor: '#E0FFFF',
color: '#008080',
fontWeight: 'bold',
border: 'none',
cursor: 'pointer',
margin: '0.5em',
borderRadius: '5px',
boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
minWidth: '50px',
fontSize: '0.9em',
textAlign: 'center',
},
navList: {
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center', // Align items to the center vertically
},
navItem: {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '1em', // Adjust margin for spacing
},
logo: {
  height: '70%', // Increased height of the logo
  marginRight: '10px',
},
navText: {
  backgroundColor: 'transparent', // Removed background color
  color: '#008080', // Dark teal for text
  fontSize: '1.7em', // Default font size
  fontWeight: 'bold',
  '@media (max-width: 768px)': {
    fontSize: '1.2em', // Smaller font size for tablets
  },
  '@media (max-width: 480px)': {
    fontSize: '0.1em', // Smaller font size for mobile phones
  },
},
};

export default Dashboard;
