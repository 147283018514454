import React, { useState, useEffect } from 'react';
import { database, ref, get, child, set,getDatabase,onValue } from './firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import './Registration.css'; // Import the CSS for styling
import Dropdowns from './Dropdowns'; // Import the Dropdowns component
import DeliveryCodeComponent from './DeliveryCodeComponent';
import NavBar from './NavTaskBar'; // Import NavBar component
import imagePath from './bancsuplex.jpg';



const districtsToCities = {
    'Colombo': ['Colombo 01', 'Colombo 02', 'Colombo 03', 'Colombo 04', 'Colombo 05', 'Colombo 06', 'Colombo 07', 'Colombo Fort'],
    'Kandy': ['Kandy', 'Akurana', 'Gampola', 'Mahaiyawa', 'Peradeniya'],
    'Nuwara Eliya': ['Nuwara Eliya', 'Hatton', 'Haputale', 'Balangoda', 'Bogowanthalawa'],
    'Galle': ['Galle', 'Ambalangoda', 'Hikkaduwa', 'Unawatuna'],
    'Matara': ['Matara', 'Dikwella', 'Weligama', 'Tangalle'],
    'Jaffna': ['Jaffna', 'Point Pedro', 'Chavakachcheri', 'Tellippalai'],
    'Kurunegala': ['Kurunegala', 'Maho', 'Alawwa', 'Pannala'],
    'Ratnapura': ['Ratnapura', 'Elapatha', 'Eheliyagoda', 'Balangoda'],
    'Gampaha': ['Negombo', 'Gampaha', 'Wattala', 'Minuwangoda'],
    'Batticaloa': ['Batticaloa', 'Kaluvanchikudy', 'Eravur', 'Kalkudah'],
    'Trincomalee': ['Trincomalee', 'Kantalai', 'Muttur', 'Seruwila'],
    // Add other districts and cities here as needed
};

const banks = [
    'Bank of Ceylon',
    'Commercial Bank',
    'HSBC',
    'Seylan Bank',
    'People’s Bank',
    'DFCC Bank',
    'Union Bank',
    'Nations Trust Bank',
    'HNB',
    'Standard Chartered Bank',
];

const branches = {
    'Bank of Ceylon': [
        'Colombo Fort', 'Kandy', 'Galle', 'Matara', 'Nuwara Eliya', 
        'Kurunegala', 'Jaffna', 'Negombo', 'Ratnapura', 'Trincomalee',
        'Anuradhapura', 'Badulla', 'Batticaloa', 'Kalutara', 'Puttalam',
        'Mannar', 'Vavuniya', 'Hambantota', 'Dambulla', 'Hatton', 
        'Bandarawela', 'Ambalangoda', 'Beruwala', 'Dehiwala-Mount Lavinia',
        'Homagama', 'Kaduwela', 'Kesbewa', 'Kolonnawa', 'Kotte',
        'Maharagama', 'Moratuwa', 'Padukka', 'Ratmalana', 'Seethawaka',
        'Thimbirigasyaya', 'Ampara', 'Batticaloa', 'Eravur Town', 
        'Kalmunai', 'Vakarai', 'Akurana', 'Alawatugoda', 'Ambatenna',
        'Daskara', 'Daulagala', 'Delthota', 'Doluwa', 'Galagedara',
        'Galhinna', 'Gampola', 'Ganga Ihala Korale', 'Hanguranketa',
        'Hapugastalawa', 'Harispattuwa', 'Hatharaliyadda', 'Kadugannawa',
        'Kandy Four Gravets', 'Katugastota', 'Kundasale', 'Madawala',
        'Medadumbara', 'Menikdiwela', 'Minipe', 'Nawalapitiya', 
        'Pallekele', 'Panvila', 'Pasbage Korale', 'Pathadumbara', 
        'Pathahewaheta', 'Peradeniya', 'Pilimathalawa', 'Poojapitiya', 
        'Pussellawa', 'Talatuoya', 'Teldeniya', 'Thumpane', 'Udadumbara',
        'Udapalatha', 'Ududumbara', 'Udunuwara', 'Ulapane', 'Watadeniya',
        'Wattegama', 'Welamboda', 'Yatinuwara', 'Mirissa city', 
        'Talpe', 'Tangalle', 'Unawatuna', 'Weligama'
    ],
    'Commercial Bank': [
        'Colombo 07', 'Jaffna', 'Kurunegala', 'Kandy', 'Galle', 
        'Matara', 'Negombo', 'Ratnapura', 'Trincomalee', 'Anuradhapura',
        'Badulla', 'Batticaloa', 'Kalutara', 'Puttalam', 'Mannar',
        'Vavuniya', 'Hambantota', 'Dambulla', 'Hatton', 
        'Bandarawela', 'Ambalangoda', 'Beruwala', 'Dehiwala-Mount Lavinia',
        'Homagama', 'Kaduwela', 'Kesbewa', 'Kolonnawa', 'Kotte',
        'Maharagama', 'Moratuwa', 'Padukka', 'Ratmalana', 'Seethawaka',
        'Thimbirigasyaya', 'Ampara', 'Batticaloa', 'Eravur Town', 
        'Kalmunai', 'Vakarai', 'Akurana', 'Alawatugoda', 'Ambatenna',
        'Daskara', 'Daulagala', 'Delthota', 'Doluwa', 'Galagedara',
        'Galhinna', 'Gampola', 'Ganga Ihala Korale', 'Hanguranketa',
        'Hapugastalawa', 'Harispattuwa', 'Hatharaliyadda', 'Kadugannawa',
        'Kandy Four Gravets', 'Katugastota', 'Kundasale', 'Madawala',
        'Medadumbara', 'Menikdiwela', 'Minipe', 'Nawalapitiya', 
        'Pallekele', 'Panvila', 'Pasbage Korale', 'Pathadumbara', 
        'Pathahewaheta', 'Peradeniya', 'Pilimathalawa', 'Poojapitiya', 
        'Pussellawa', 'Talatuoya', 'Teldeniya', 'Thumpane', 'Udadumbara',
        'Udapalatha', 'Ududumbara', 'Udunuwara', 'Ulapane', 'Watadeniya',
        'Wattegama', 'Welamboda', 'Yatinuwara', 'Mirissa city', 
        'Talpe', 'Tangalle', 'Unawatuna', 'Weligama'
    ],
    'HSBC': [
        'Colombo', 'Kandy', 'Galle', 'Nuwara Eliya',
        'Negombo', 'Maharagama', 'Kalutara', 'Ratnapura', 'Hatton', 
        'Bandarawela', 'Dambulla', 'Dehiwala-Mount Lavinia',
        'Homagama', 'Kaduwela', 'Kesbewa', 'Kolonnawa', 'Kotte',
        'Maharagama', 'Moratuwa', 'Padukka', 'Ratmalana', 'Seethawaka',
        'Thimbirigasyaya', 'Ampara', 'Batticaloa', 'Eravur Town', 
        'Kalmunai', 'Vakarai', 'Akurana', 'Alawatugoda', 'Ambatenna',
        'Daskara', 'Daulagala', 'Delthota', 'Doluwa', 'Galagedara',
        'Galhinna', 'Gampola', 'Ganga Ihala Korale', 'Hanguranketa',
        'Hapugastalawa', 'Harispattuwa', 'Hatharaliyadda', 'Kadugannawa',
        'Kandy Four Gravets', 'Katugastota', 'Kundasale', 'Madawala',
        'Medadumbara', 'Menikdiwela', 'Minipe', 'Nawalapitiya', 
        'Pallekele', 'Panvila', 'Pasbage Korale', 'Pathadumbara', 
        'Pathahewaheta', 'Peradeniya', 'Pilimathalawa', 'Poojapitiya', 
        'Pussellawa', 'Talatuoya', 'Teldeniya', 'Thumpane', 'Udadumbara',
        'Udapalatha', 'Ududumbara', 'Udunuwara', 'Ulapane', 'Watadeniya',
        'Wattegama', 'Welamboda', 'Yatinuwara', 'Mirissa city', 
        'Talpe', 'Tangalle', 'Unawatuna', 'Weligama'
    ],
    'Seylan Bank': [
        'Colombo 03', 'Kandy', 'Matara', 'Galle', 'Kurunegala',
        'Jaffna', 'Negombo', 'Badulla', 'Batticaloa', 'Hambantota',
        'Trincomalee', 'Anuradhapura', 'Vavuniya', 'Mannar', 
        'Nuwara Eliya', 'Dambulla', 'Hatton', 'Bandarawela', 
        'Ambalangoda', 'Beruwala', 'Dehiwala-Mount Lavinia', 
        'Homagama', 'Kaduwela', 'Kesbewa', 'Kolonnawa', 'Kotte',
        'Maharagama', 'Moratuwa', 'Padukka', 'Ratmalana', 'Seethawaka',
        'Thimbirigasyaya', 'Ampara', 'Batticaloa', 'Eravur Town', 
        'Kalmunai', 'Vakarai', 'Akurana', 'Alawatugoda', 'Ambatenna',
        'Daskara', 'Daulagala', 'Delthota', 'Doluwa', 'Galagedara',
        'Galhinna', 'Gampola', 'Ganga Ihala Korale', 'Hanguranketa',
        'Hapugastalawa', 'Harispattuwa', 'Hatharaliyadda', 'Kadugannawa',
        'Kandy Four Gravets', 'Katugastota', 'Kundasale', 'Madawala',
        'Medadumbara', 'Menikdiwela', 'Minipe', 'Nawalapitiya', 
        'Pallekele', 'Panvila', 'Pasbage Korale', 'Pathadumbara', 
        'Pathahewaheta', 'Peradeniya', 'Pilimathalawa', 'Poojapitiya', 
        'Pussellawa', 'Talatuoya', 'Teldeniya', 'Thumpane', 'Udadumbara',
        'Udapalatha', 'Ududumbara', 'Udunuwara', 'Ulapane', 'Watadeniya',
        'Wattegama', 'Welamboda', 'Yatinuwara', 'Mirissa city', 
        'Talpe', 'Tangalle', 'Unawatuna', 'Weligama'
    ],
    'People’s Bank': [
        'Colombo Fort', 'Colombo 03', 'Galle', 'Matara', 'Kandy', 
        'Kurunegala', 'Jaffna', 'Negombo', 'Badulla', 'Ratnapura', 
        'Trincomalee', 'Vavuniya', 'Hambantota', 'Dambulla', 'Hatton', 
        'Bandarawela', 'Ambalangoda', 'Beruwala', 'Dehiwala-Mount Lavinia',
        'Homagama', 'Kaduwela', 'Kesbewa', 'Kolonnawa', 'Kotte',
        'Maharagama', 'Moratuwa', 'Padukka', 'Ratmalana', 'Seethawaka',
        'Thimbirigasyaya', 'Ampara', 'Batticaloa', 'Eravur Town', 
        'Kalmunai', 'Vakarai', 'Akurana', 'Alawatugoda', 'Ambatenna',
        'Daskara', 'Daulagala', 'Delthota', 'Doluwa', 'Galagedara',
        'Galhinna', 'Gampola', 'Ganga Ihala Korale', 'Hanguranketa',
        'Hapugastalawa', 'Harispattuwa', 'Hatharaliyadda', 'Kadugannawa',
        'Kandy Four Gravets', 'Katugastota', 'Kundasale', 'Madawala',
        'Medadumbara', 'Menikdiwela', 'Minipe', 'Nawalapitiya', 
        'Pallekele', 'Panvila', 'Pasbage Korale', 'Pathadumbara', 
        'Pathahewaheta', 'Peradeniya', 'Pilimathalawa', 'Poojapitiya', 
        'Pussellawa', 'Talatuoya', 'Teldeniya', 'Thumpane', 'Udadumbara',
        'Udapalatha', 'Ududumbara', 'Udunuwara', 'Ulapane', 'Watadeniya',
        'Wattegama', 'Welamboda', 'Yatinuwara', 'Mirissa city', 
        'Talpe', 'Tangalle', 'Unawatuna', 'Weligama'
    ],
    'DFCC Bank': [
        'Colombo Fort', 'Colombo 03', 'Galle', 'Matara', 'Kandy', 
        'Kurunegala', 'Jaffna', 'Negombo', 'Badulla', 'Ratnapura', 
        'Trincomalee', 'Vavuniya', 'Hambantota', 'Dambulla', 'Hatton', 
        'Bandarawela', 'Ambalangoda', 'Beruwala', 'Dehiwala-Mount Lavinia',
        'Homagama', 'Kaduwela', 'Kesbewa', 'Kolonnawa', 'Kotte',
        'Maharagama', 'Moratuwa', 'Padukka', 'Ratmalana', 'Seethawaka',
        'Thimbirigasyaya', 'Ampara', 'Batticaloa', 'Eravur Town', 
        'Kalmunai', 'Vakarai', 'Akurana', 'Alawatugoda', 'Ambatenna',
        'Daskara', 'Daulagala', 'Delthota', 'Doluwa', 'Galagedara',
        'Galhinna', 'Gampola', 'Ganga Ihala Korale', 'Hanguranketa',
        'Hapugastalawa', 'Harispattuwa', 'Hatharaliyadda', 'Kadugannawa',
        'Kandy Four Gravets', 'Katugastota', 'Kundasale', 'Madawala',
        'Medadumbara', 'Menikdiwela', 'Minipe', 'Nawalapitiya', 
        'Pallekele', 'Panvila', 'Pasbage Korale', 'Pathadumbara', 
        'Pathahewaheta', 'Peradeniya', 'Pilimathalawa', 'Poojapitiya', 
        'Pussellawa', 'Talatuoya', 'Teldeniya', 'Thumpane', 'Udadumbara',
        'Udapalatha', 'Ududumbara', 'Udunuwara', 'Ulapane', 'Watadeniya',
        'Wattegama', 'Welamboda', 'Yatinuwara', 'Mirissa city', 
        'Talpe', 'Tangalle', 'Unawatuna', 'Weligama'
    ],
    'Union Bank': [
        'Colombo Fort', 'Colombo 03', 'Galle', 'Matara', 'Kandy', 
        'Kurunegala', 'Jaffna', 'Negombo', 'Badulla', 'Ratnapura', 
        'Trincomalee', 'Vavuniya', 'Hambantota', 'Dambulla', 'Hatton', 
        'Bandarawela', 'Ambalangoda', 'Beruwala', 'Dehiwala-Mount Lavinia',
        'Homagama', 'Kaduwela', 'Kesbewa', 'Kolonnawa', 'Kotte',
        'Maharagama', 'Moratuwa', 'Padukka', 'Ratmalana', 'Seethawaka',
        'Thimbirigasyaya', 'Ampara', 'Batticaloa', 'Eravur Town', 
        'Kalmunai', 'Vakarai', 'Akurana', 'Alawatugoda', 'Ambatenna',
        'Daskara', 'Daulagala', 'Delthota', 'Doluwa', 'Galagedara',
        'Galhinna', 'Gampola', 'Ganga Ihala Korale', 'Hanguranketa',
        'Hapugastalawa', 'Harispattuwa', 'Hatharaliyadda', 'Kadugannawa',
        'Kandy Four Gravets', 'Katugastota', 'Kundasale', 'Madawala',
        'Medadumbara', 'Menikdiwela', 'Minipe', 'Nawalapitiya', 
        'Pallekele', 'Panvila', 'Pasbage Korale', 'Pathadumbara', 
        'Pathahewaheta', 'Peradeniya', 'Pilimathalawa', 'Poojapitiya', 
        'Pussellawa', 'Talatuoya', 'Teldeniya', 'Thumpane', 'Udadumbara',
        'Udapalatha', 'Ududumbara', 'Udunuwara', 'Ulapane', 'Watadeniya',
        'Wattegama', 'Welamboda', 'Yatinuwara', 'Mirissa city', 
        'Talpe', 'Tangalle', 'Unawatuna', 'Weligama'
],
'HNB': [
    'Colombo Fort', 'Colombo 03', 'Galle', 'Matara', 'Kandy', 
    'Kurunegala', 'Jaffna', 'Negombo', 'Badulla', 'Ratnapura', 
    'Trincomalee', 'Vavuniya', 'Hambantota', 'Dambulla', 'Hatton', 
    'Bandarawela', 'Ambalangoda', 'Beruwala', 'Dehiwala-Mount Lavinia',
    'Homagama', 'Kaduwela', 'Kesbewa', 'Kolonnawa', 'Kotte',
    'Maharagama', 'Moratuwa', 'Padukka', 'Ratmalana', 'Seethawaka',
    'Thimbirigasyaya', 'Ampara', 'Batticaloa', 'Eravur Town', 
    'Kalmunai', 'Vakarai', 'Akurana', 'Alawatugoda', 'Ambatenna',
    'Daskara', 'Daulagala', 'Delthota', 'Doluwa', 'Galagedara',
    'Galhinna', 'Gampola', 'Ganga Ihala Korale', 'Hanguranketa',
    'Hapugastalawa', 'Harispattuwa', 'Hatharaliyadda', 'Kadugannawa',
    'Kandy Four Gravets', 'Katugastota', 'Kundasale', 'Madawala',
    'Medadumbara', 'Menikdiwela', 'Minipe', 'Nawalapitiya', 
    'Pallekele', 'Panvila', 'Pasbage Korale', 'Pathadumbara', 
    'Pathahewaheta', 'Peradeniya', 'Pilimathalawa', 'Poojapitiya', 
    'Pussellawa', 'Talatuoya', 'Teldeniya', 'Thumpane', 'Udadumbara',
    'Udapalatha', 'Ududumbara', 'Udunuwara', 'Ulapane', 'Watadeniya',
    'Wattegama', 'Welamboda', 'Yatinuwara', 'Mirissa city', 
    'Talpe', 'Tangalle', 'Unawatuna', 'Weligama'
]
};



const accountTypes = ['Savings', 'Current', 'Fixed Deposit'];
const branchDetails = {
    'Akurana': {
        code: 'CP01',
        name: 'SUPLEX ASHIA',
        address: '24 Thelumbugavatta Akurana, Kandy',
        contact: '0777976510,0777976501',
    },
    'Hatton': {
        code: 'CP02',
        name: 'SUPLEX ASHIA',
        address: '84/12 Danbar road, Hatton',
        contact: '0777976510,0777976501',
    }
};
const Registration = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        accountHolderName: '',
        accountNumber: '',
        accountType: '',
        address: '',
        bank: '',
        branch: '',
        city: '',
        deliveryCode: '',
        district: '',
        email: '',
        extraTel: '',
        name: '',
        nic: '',
        phone: '',
        placementTop: '',
        placementopUnit:'',
        presenter: '',
        teamLeader: '',
        teamLeader: '',
        teamManager: '',
        unit: '',
        regDate: ''
    });
    const [filteredUsers, setFilteredUsers] = useState([]);

   
    const [teamLeaders, setTeamLeaders] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [cities, setCities] = useState([]);
    const [branchOptions, setBranchOptions] = useState([]);
    const [selectedBranchDetails, setSelectedBranchDetails] = useState(null);
    const [nicError, setNicError] = useState(''); // State for NIC error message
    const [nicExists, setNicExists] = useState(false);
    const [hideBranchDetails, setHideBranchDetails] = useState(false); // New state for hiding branch details
    const [branchCheckbox, setBranchCheckbox] = useState(false); // New state for the tick mark (checkbox)
    const [usedTeamLeaders, setUsedTeamLeaders] = useState(new Set()); // State to keep track of used team leaders
    const [selectedLeaderDetails, setSelectedLeaderDetails] = useState('');
    // Fetch presenters and delivery codes from Firebase
    const [presenters, setPresenters] = useState([]);
    const [deliveryCodes, setDeliveryCodes] = useState([]);
    // New state for checkboxes
    const [selectedBranchCodes, setSelectedBranchCodes] = useState({
        CP01: false,
        CP02: false,
    });
    

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const snapshot = await get(child(ref(database), 'adminConfig'));
                if (snapshot.exists()) {
                    const configData = snapshot.val();
                    setPresenters(configData.presenters || []);
                    setDeliveryCodes(configData.deliveryCodes || []);
                }
            } catch (error) {
                console.error("Error fetching admin config data:", error);
            }
        };

        fetchConfig();
    }, []);

    useEffect(() => {
        const fetchUserData = async (username) => {
            try {
                const snapshot = await get(child(ref(database), 'users'));
                if (snapshot.exists()) {
                    const users = snapshot.val();
                    const user = users[username];
                    if (user) {
                        setFormData(prevData => ({
                            ...prevData,
                            placementTop: user.username,
                            teamManager: `${user.name} (${user.username})`,
                        }));
                        fetchTeamLeadersAndUnits(user.username);
                        setCities(districtsToCities[user.district] || []);
                        setBranchOptions(branches[user.bank] || []);
                    }
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        if (location.state && location.state.username) {
            fetchUserData(location.state.username);
        }
    }, [location.state]);

    useEffect(() => {
        setCities(districtsToCities[formData.district] || []);
        setSelectedBranchDetails(null); // Reset branch details when district changes
    }, [formData.district]);

    useEffect(() => {
        setBranchOptions(branches[formData.bank] || []);
        setSelectedBranchDetails(null); // Reset branch details when bank changes
    }, [formData.bank]);

    useEffect(() => {
        const branchDetail = branchDetails[formData.city];
        setSelectedBranchDetails(branchDetail || null);
    }, [formData.city]);

    useEffect(() => {
        const updateTeamLeader = async () => {
            await fetchTeamLeadersAndUnits(formData.placementTop);
            // After fetching, ensure the new placementTop is selected if available
            setFormData(prevData => ({
                ...prevData,
                teamLeader: teamLeaders.find(leader => leader.username === formData.placementTop)?.username || ''
            }));
        };
        updateTeamLeader();
    }, [formData.placementTop]);
    const fetchTeamLeadersAndUnits = async (placementTop) => {
        try {
            const snapshot = await get(child(ref(database), 'users'));
            const users = snapshot.val();
            const leaders = new Map(); // Use a Map to avoid duplicates
    
            if (users) {
                // First, gather all team leaders and their units
                Object.entries(users).forEach(([userKey, user]) => {
                    // Check if the user matches the given placementTop
                    if (userKey === placementTop) {
                        // Add the specific placementTop user with their units
                        leaders.set(`${user.username} unit1`, {
                            id: user.id,
                            username: user.username,
                            unit: 'Unit 1', // Use "Unit 1" for the specific placementTop
                            name: user.name,
                        });
    
                        leaders.set(`${user.username} unit2`, {
                            id: user.id,
                            username: user.username,
                            unit: 'Unit 2', // Use "Unit 2" for the specific placementTop
                            name: user.name,
                        });
                    }
    
                    // Check if the user is a team leader for the given placementTop
                    if (user.teamLeader === placementTop) {
                        // Add team leaders with their units
                        leaders.set(`${user.username} unit1`, {
                            id: user.id,
                            username: user.username,
                            unit: 'Unit 1', // Add unit1 for team leaders
                            name: user.name,
                        });
    
                        leaders.set(`${user.username} unit2`, {
                            id: user.id,
                            username: user.username,
                            unit: 'Unit 2', // Add unit2 for team leaders
                            name: user.name,
                        });
                    }
                });
    
                // Include users registered under the selected team leaders
                Object.entries(users).forEach(([userKey, user]) => {
                    // Check if this user has a team leader in the leaders map
                    if (leaders.has(`${user.teamLeader} unit1`) || leaders.has(`${user.teamLeader} unit2`)) {
                        // Add the new user directly under their team leader with both units
                        leaders.set(`${user.username} unit1`, {
                            id: user.id,
                            username: user.username,
                            unit: 'Unit 1', // Explicitly add Unit 1 for the new user
                            name: user.name,
                        });
    
                        leaders.set(`${user.username} unit2`, {
                            id: user.id,
                            username: user.username,
                            unit: 'Unit 2', // Explicitly add Unit 2 for the new user
                            name: user.name,
                        });
                    }
                });
            }
    
            // Convert Map to Array and filter out used team leaders
            const filteredLeaders = Array.from(leaders.values()).filter(leader => {
                const baseUsername = leader.username; // Extract the base username for filtering
                return !usedTeamLeaders.has(baseUsername); // Only include leaders not already used
            });
    
            // Update state with filtered leaders
            setTeamLeaders(filteredLeaders);
        } catch (error) {
            console.error('Error fetching team leaders and units:', error);
        }
    };
    
    
    
    
    
    
    // Assuming that this function filters out team leaders by unit as per requirement
    const filterTeamLeadersByUnit = (placementTop, selectedUnit) => {
        return teamLeaders.filter(leader => leader.unit !== selectedUnit);
    };
    
    const getNextUnit = async (placementTop) => {
        const availableUnits = ['Unit 1', 'Unit 2', 'Unit 3']; // Define available units
        const dbRef = ref(database);
        
        try {
            const snapshot = await get(child(dbRef, 'users'));
            const users = snapshot.val();
            
            if (users) {
                // Find the units already assigned under the given placementTop
                const assignedUnits = Object.values(users)
                    .filter(user => user.placementTop === placementTop)
                    .map(user => user.unit);
    
                // Get the last assigned unit and find the next one in a cyclic manner
                if (assignedUnits.length > 0) {
                    const lastAssignedUnit = assignedUnits[assignedUnits.length - 1];
                    const lastAssignedIndex = availableUnits.indexOf(lastAssignedUnit);
                    
                    // Calculate the next unit index cyclically
                    const nextUnitIndex = (lastAssignedIndex + 1) % availableUnits.length;
                    return availableUnits[nextUnitIndex];
                }
            }
    
            // If no units are assigned yet, start with Unit 1
            return 'Unit 1';
    
        } catch (error) {
            console.error('Error fetching users or determining the next unit:', error);
            return 'Unit 1'; // Default to Unit 1 if there's an error
        }
    };
    
    

    const generateUsername = async () => {
        try {
            const snapshot = await get(child(ref(database), 'users'));
            const users = snapshot.val();
            let maxNumber = 0;
    
            if (users) {
                Object.values(users).forEach(user => {
                    if (user.username.startsWith('SRI')) {
                        const numberPart = parseInt(user.username.slice(3), 10);
                        if (!isNaN(numberPart) && numberPart > maxNumber) {
                            maxNumber = numberPart;
                        }
                    }
                });
            }
    
            const newUsername = `SRI${String(maxNumber + 1).padStart(5, '0')}`;
            return newUsername;
        } catch (error) {
            console.error('Error generating username:', error);
            return null;
        }
    };
    


    const generatePassword = () => {
        const length = 10;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let password = "";
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        return password;
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if NIC already exists before proceeding
        const nicExists = await checkNICExists(formData.nic);
        if (nicExists) {
            alert('NIC already exists. Please use a different NIC.');
            return;
        }
    
        // Capture the current date as the registration date
        const registrationDate = new Date().toLocaleDateString();
        setFormData(prevData => ({
            ...prevData,
            regDate: registrationDate
        }));
    
        // Generate password and username
        const newPassword = generatePassword();
        setPassword(newPassword);
    
        const newUsername = await generateUsername();
        if (!newUsername) {
            alert('Failed to generate username.');
            return;
        }
        setUsername(newUsername);
    
        const userId = newUsername;
        const { email, placementTop } = formData;
    
        // Find selected team leader and get their unit
        const selectedLeader = teamLeaders.find(leader => leader.username === formData.teamLeader);
        const leaderUnit = selectedLeader ? selectedLeader.unit : null;
    
        // Keep the original placementTop and combine it with the leader's unit for placementopUnit
        const placementopUnit = leaderUnit ? `${placementTop}_${selectedLeaderDetails}` : placementTop;
    
        const userDataBase = {
            ...formData,
            username: newUsername,
            password: newPassword,
            id: userId,
            regDate: registrationDate,
            hideBranchDetails,
            selectedLeaderDetails,  // Keep this as it is
            placementopUnit,        // Store combined `placementTop` and team leader's unit
        };
    
        try {
            // Save user entry in the database using the selected leader's unit
            if (leaderUnit) {
                await set(ref(database, `users/${userId}`), { ...userDataBase, unit: leaderUnit });
            } else {
                alert('Failed to register user: Team leader unit not found.');
                return;
            }
    
            alert(`User Registered Successfully!\nUsername: ${newUsername}\nEmail: ${formData.email}\nPassword: ${newPassword}`);
            navigate('/sendmail', { state: { newUsername, newPassword, email } });
    
        } catch (error) {
            console.error('Error registering user:', error);
            alert('Failed to register user.');
        }
    };
    
    
    
    
    
    
    
    
    
    
    const checkNICExists = async (nic) => {
        try {
            const snapshot = await get(child(ref(database), 'users'));
            const users = snapshot.val();

            if (users) {
                return Object.values(users).some(user => user.nic === nic);
            }

            return false; // NIC does not exist
        } catch (error) {
            console.error('Error checking NIC existence:', error);
            return false;
        }
    };
    
    const handleChange = async (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const updatedData = { ...prevData, [name]: value };
            localStorage.setItem('registrationFormData', JSON.stringify(updatedData)); // Save to localStorage
            return updatedData;
        });
    
        if (name === 'nic') {
            if (value.length !== 10) {
                setNicError('NIC should be 10 characters long e.g., 0777777777');
                setNicExists(false); // Reset existence check
            } else {
                const exists = await checkNICExists(value);
                setNicExists(exists);
                setNicError(exists ? 'NIC already exists' : '');
            }
        }
    };
    

    const handleTeamLeaderChange = (e) => {
        const selectedValue = e.target.value;
    
        if (selectedValue) {
            const [username, unit] = selectedValue.split('-'); // Extract the username and unit from the selection
    
            // Find the selected leader based on the username and the specified unit
            const selectedLeader = filterTeamLeadersByUnit(formData.placementTop, unit.trim())
                .find(leader => leader.username === username.trim()); // Use trim to avoid spaces
    
            if (selectedLeader) {
                // Update formData with the selected team leader's username
                setFormData(prevData => ({
                    ...prevData,
                    teamLeader: username.trim(), // Only store the username
                }));
    
                // Fetch the selected leader's full details from the database
                const db = getDatabase();
                const userRef = ref(db, `users/${selectedLeader.id}`); // Assuming you have an ID to fetch user details
    
                onValue(userRef, (snapshot) => {
                    const leaderDetails = snapshot.val();
    
                    if (leaderDetails) {
                        // Check if placementTop and team leader are the same
                        if (formData.placementTop === username.trim()) {
                            // If they match, update selectedLeaderDetails with the selected unit
                            setSelectedLeaderDetails(unit.trim());
                        } else {
                            // If they differ, keep current leader details
                            setSelectedLeaderDetails(leaderDetails.selectedLeaderDetails || '');
                        }
                    } else {
                        alert('No matching leader details found in the database.');
                        setSelectedLeaderDetails('');
                    }
                });
            } else {
                alert('No matching leader found.');
                setSelectedLeaderDetails('');
            }
        } else {
            // Reset the selected leader details if no leader is selected
            setSelectedLeaderDetails('');
        }
    };
    
    
    
    
    

    
    
    const handleBranchToggle = () => {
        setHideBranchDetails(!hideBranchDetails);
    };
    const handleBranchCheckboxChange = (e) => {
        setBranchCheckbox(true);
        if (e.target.checked && selectedBranchDetails) {
            // Add branch details to formData when the checkbox is checked
            setFormData((prevData) => ({
                ...prevData,
                branchCode: selectedBranchDetails.code,
                branchName: selectedBranchDetails.name,
                branchAddress: selectedBranchDetails.address,
                branchContact: selectedBranchDetails.contact,
            }));
        } else {
            // Reset branch details if the checkbox is unchecked
            setFormData((prevData) => ({
                ...prevData,
                branchCode: '',
                branchName: '',
                branchAddress: '',
                branchContact: '',
            }));
        }
    };
    
    const handleReset = () => {
        setFormData({
            accountHolderName: '',
            accountNumber: '',
            accountType: 'Savings',
            address: '',
            bank: '',
            branch: '',
            city: '',
            deliveryCode: '',
            district: '',
            email: '',
            extraTel: '',
            name: '',
            nic: '',
            phone: '',
            presenter: '',
            teamLeader: '',
            unit: '',
            regDate: '', // Add the regDate field
        });
        setUsername('');
        setPassword('');
        setTeamLeaders([]);
        setCities([]);
        setBranchOptions([]);
        setSelectedBranchDetails(null);
    };
    // New function to handle checkbox changes
    const handleCheckboxChange = (code) => {
        setSelectedBranchCodes(prev => ({ ...prev, [code]: !prev[code] }));
    };
    const initialFormData = {
        accountHolderName: '',
        accountNumber: '',
        accountType: '',
        address: '',
        bank: '',
        branch: '',
        city: '',
        deliveryCode: '',
        district: '',
        email: '',
        extraTel: '',
        name: '',
        nic: '',
        phone: '',
        placementTop: '',
        presenter: '',
        teamLeader: '',
        teamLeader: '',
        teamManager: '',
        unit: '',
        regDate: ''
    };

    useEffect(() => {
        // Retrieve form data from localStorage when component mounts
        const savedFormData = localStorage.getItem('registrationFormData');
        if (savedFormData) {
            setFormData(JSON.parse(savedFormData));
        }
    }, []);
    return (
            <div style={styles.wrapper}>
            <NavBar /> {/* Add the Navbar here */}
            <form onSubmit={handleSubmit}>
        <div className="form-section">
        <h3>Registration Form</h3>
            <h3 className="underline">Step 4 of 4: Customer General Details</h3>
            <label3>All Fields should be filled.</label3>
            <label2>(Fields marked with * should be actual detail for the user to be unique)</label2>
            <label>Team Manager:</label>
            <input
                type="text"
                name="teamManager"
                value={formData.teamManager}
                onChange={handleChange}
                readOnly
                required
            />
            
            <label>District:</label>
            <select
                name="district"
                value={formData.district}
                onChange={handleChange}
                required
            >
                {Object.keys(districtsToCities).map(district => (
                    <option key={district} value={district}>{district}</option>
                ))}
            </select>

{formData.district && ( // Show the following fields only if a district is selected
    <>
        <label>City:</label>
        <select
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
        >
            {districtsToCities[formData.district]?.map(city => ( // Adjusted to fetch cities based on selected district
                <option key={city} value={city}>{city}</option>
            ))}
        </select>
    </>
)}

            {formData.city && ( // Show the following fields only if a city is selected
                <>
            <div>
            {/* Show the checkbox only if it is not checked */}
            {!branchCheckbox && selectedBranchDetails && (
                <div style={styles.container}>
                    <label style={{ display: 'flex', alignItems: 'center' }}>
                        Branch Code: {selectedBranchDetails.code}
                        <input
                            type="checkbox"
                            checked={branchCheckbox}
                            onChange={handleBranchCheckboxChange}
                            style={{ marginLeft: '5px' }} // Minimal space between text and checkbox
                        />
                    </label>
                    <p style={styles.textStyle}>Branch Name: {selectedBranchDetails.name}</p>
                    <p style={styles.textStyle}>Branch Address: {selectedBranchDetails.address}</p>
                    <p style={styles.textStyle}>Branch Contact: {selectedBranchDetails.contact}</p>
                </div>
            )}
        </div>

                    <label>Delivery Code:</label>
                    <DeliveryCodeComponent
                        name="deliveryCode"
                        value={formData.deliveryCode}
                        onChange={handleChange}
                        options={deliveryCodes}
                        required
                    />
                    <label>Presenter:</label>
                    <Dropdowns
                        name="presenter"
                        value={formData.presenter}
                        onChange={handleChange}
                        options={presenters}
                        required
                    />
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    
                    <label>Address:</label>
                    <input
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        required
                    />
                    <label>Email:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    
                    <label>Phone:</label>
                    <input
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                    <label2>(Should be 10 characters long e.g. 0777777777)</label2>
                    <label>Extra Tel:</label>
                    <input
                        type="text"
                        name="extraTel"
                        value={formData.extraTel}
                        onChange={handleChange}
                        required
                    />
                    <label2>(Should be 10 characters long e.g. 0777777777)</label2>
                    <div className="form-group">
                        <label>NIC:</label>
                        <input
                            type="text"
                            name="nic"
                            value={formData.nic}
                            onChange={handleChange}
                            required
                        />
                        {nicError && <p className="error-message" style={{ color: 'red', marginLeft: '20px' }}>{nicError}</p>} {/* Display NIC error message */}
                    </div>
                    <label2>(Should be 10 or 12 characters long e.g. 999999999V or 888888888888)</label2>
                    <label>Placement Top:</label>
                    <input
                        type="text"
                        name="placementTop"
                        value={formData.placementTop}
                        onChange={handleChange}
                        required
                    />
             
                    <label>Select Team Leader:</label>
                    <select
                        name="teamLeader"
                        value={formData.teamLeader}
                        onChange={handleTeamLeaderChange} // Use the new handler
                    >
                        <option value="">Select Team Leader</option>
                        {filterTeamLeadersByUnit(formData.placementTop, 'Unit 3').map((leader) => (
                            <option 
                                key={leader.id} 
                                value={`${leader.username}-${leader.unit}`} // Combine username and unit
                            >
                                {leader.username} - {leader.unit} - {leader.name}
                            </option>
                        ))}
                    </select>

                    {/* Display the selected team leader details */}
                    {formData.teamLeader && (
                        <label>Selected Team Leader: {formData.teamLeader} </label>
                    )}



                {/* Autofill field for team leader credentials */}
               
                <input
                   type="hide"
                    value={selectedLeaderDetails} // Autofill value
                    readOnly // Make it read-only if you want it to be non-editable
                    onChange={handleTeamLeaderChange} // Use the new handler
                    required
                    
                />

                    <h3 className="underline">Account Details</h3>
                    <label2>(Fields marked with * should be actual detail for the user to be unique)</label2>
                    <label>Bank:</label>
                    <select
                        name="bank"
                        value={formData.bank}
                        onChange={handleChange}
                        required
                    >
                        {banks.map(bank => (
                            <option key={bank} value={bank}>{bank}</option>
                        ))}
                    </select>
                    <label>Branch:</label>
                    <select
                        name="branch"
                        value={formData.branch}
                        onChange={handleChange}
                        required
                    >
                        {branchOptions.map(branch => (
                            <option key={branch} value={branch}>{branch}</option>
                        ))}
                    </select>
                    <label>Account Type:</label>
                    <select
                        name="accountType"
                        value={formData.accountType}
                        onChange={handleChange}
                        required
                    >
                        {accountTypes.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </select>
                    <label>Account Holder Name:</label>
                    <input
                        type="text"
                        name="accountHolderName"
                        value={formData.accountHolderName}
                        onChange={handleChange}
                        required
                    />
                    <label>Account Number:</label>
                    <input
                        type="text"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleChange}
                        required
                    />
                </>
            )}

            <div className="form-buttons">
                <button type="submit">Submit</button>
                <button type="button" onClick={handleReset}>Reset</button>
            </div>
        </div>
        <div style={styles.imageContainer}></div>
    </form>
</div>
    );
};
const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh',
        backgroundColor: '#E0FFFF',
        width: '1620px', // Fixed width for non-responsiveness
        zIndex: 1,
    },
    imageContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '300px',
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`,
        backgroundSize: 'cover',
        zIndex: -1,
    },
    textStyle: {
        fontFamily: 'Arial, sans-serif', // Example font
        color: 'teal', // Example color
        marginLeft: '20px', // Margin for alignment
        fontSize: '14px', // Set font size here
        fontWeight: 'bold', // Make the text bold
    },
    container: {
        backgroundColor: '#B2E0D9', // Light teal background color
        padding: '10px', // Padding around the content
        borderRadius: '16px', // Rounded corners
        margin: '10px 0', // Margin above and below the container
        maxWidth: '400px', // Set max width (you can adjust the value as needed)
        width: '100%', // Ensure it takes full width within the max limit
        marginLeft: '20px'
    },
};


export default Registration;
