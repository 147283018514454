import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDatabase, ref, get, onValue } from 'firebase/database';
import NavTaskBar from './NavTaskBar'; // Adjust the import path as necessary
import imagePath from './bancsuplex.jpg'; // Import the same background image

const database = getDatabase();

function TermsAndConditions() {
    const location = useLocation();
    const navigate = useNavigate(); // Initialize useNavigate for navigation
    const { username } = location.state; // Retrieve username passed from the Login component
    const [error, setError] = useState('');
    const [userName, setUserName] = useState(''); // Add state for userName
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); // State for checkbox

    // NavBar state variables
    const [dropdownItems, setDropdownItems] = useState([]); // Assuming dropdown items are an array
    const [openDropdown, setOpenDropdown] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const dropdownRef = React.useRef(null);
    const menuRef = React.useRef(null);

    // Fetch and set the userName from Firebase
    useEffect(() => {
        console.log('PlacementTop received:', username); // Debugging: Check if placementTop is being received correctly
        if (username) {
            const db = getDatabase();
            const usersRef = ref(db, 'users');
            onValue(usersRef, (snapshot) => {
                const users = snapshot.val();
                if (users) {
                    const user = Object.values(users).find(user => user.username === username);
                    if (user) {
                        setUserName(user.name);
                    } else {
                        setUserName('User not found');
                    }
                } else {
                    setUserName('No user data available');
                }
            }, (error) => {
                console.error('Error fetching data from Firebase:', error);
                setUserName('Error loading user');
            });
        }
    }, [username]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const userRef = ref(database, 'users/');
                const snapshot = await get(userRef);

                if (snapshot.exists()) {
                    const allUsers = snapshot.val();
                    const filteredUsers = Object.values(allUsers).filter(user => user.placementTop === username);
                } else {
                    setError('No users found.');
                }
            } catch (error) {
                console.error('Error fetching users:', error);
                setError('An error occurred while fetching users.');
            }
        };

        fetchUsers();
    }, [username]);

    // Handle checkbox change
    const handleCheckboxChange = (e) => {
        setIsCheckboxChecked(e.target.checked);
    };

    // Handle navigation to CustomerRegistrationForm
    const handleNavigate = () => {
        if (isCheckboxChecked) {
            navigate('/CustomerRegistrationForm', { state: { username } }); // Pass the username when navigating
        }
    };

    return (
        <div style={styles.wrapper}>
            <NavTaskBar 
                username={username} 
                userName={userName} // Pass the userName fetched from Firebase to the NavTaskBar
                dropdownItems={dropdownItems}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                hoverIndex={hoverIndex}
                setHoverIndex={setHoverIndex}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                dropdownRef={dropdownRef}
                menuRef={menuRef}
            />

            <div style={styles.container}>
                <h2 style={styles.heading}>Terms and Conditions</h2>
                <p style={styles.termsText}>
                    Welcome to Suplex Ashia Pvt Ltd. By accessing or using our services, you agree to be bound by the terms and conditions outlined below. 
                </p>
                <h3 style={styles.subHeading}>1. User Responsibilities</h3>
                <p style={styles.termsText}>
                    Users are responsible for providing accurate information during registration and for safeguarding their login credentials. Any unauthorized use of your account must be reported immediately.
                </p>
                <h3 style={styles.subHeading}>2. Payment and Billing</h3>
                <p style={styles.termsText}>
                    All payments made through our platform are non-refundable, except as required by law. Ensure your payment details are correct before completing any transaction.
                </p>
                <h3 style={styles.subHeading}>3. Service Changes</h3>
                <p style={styles.termsText}>
                    We reserve the right to modify or terminate our services at any time without prior notice. Users will be notified of significant changes to the terms and conditions.
                </p>
                <h3 style={styles.subHeading}>4. Limitation of Liability</h3>
                <p style={styles.termsText}>
                    Suplex Ashia Pvt Ltd will not be liable for any indirect, incidental, or consequential damages resulting from the use or inability to use our services.
                </p>

                <div style={styles.checkboxContainer}>
                    <input 
                        type="checkbox" 
                        id="acceptTerms" 
                        checked={isCheckboxChecked} 
                        onChange={handleCheckboxChange} 
                    />
                    <label htmlFor="acceptTerms">I accept the terms and conditions</label>
                </div>

                <button 
                    onClick={handleNavigate} 
                    style={isCheckboxChecked ? styles.buttonActive : styles.buttonDisabled}
                    disabled={!isCheckboxChecked} // Disable button if checkbox is not checked
                >
                    Allow
                </button>

                {error && <p style={styles.error}>{error}</p>}
            </div>
            <div style={styles.imageContainer} />

            {/* Footer */}
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Ashia. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh', // Ensure content height is maintained
       // height: 'auto', // Content determines the height
        background: '#E0FFFF', // Light teal background color
       width: '1635px', // Set to 100% for responsiveness
        maxWidth: '1635px', // Optional max-width for large screens
    },
    
    imageContainer: {
        width: '100%', // Full width of the wrapper
        height: '300px', // Fixed height for the image
        position: 'absolute', // Position it at the bottom of wrapper
        bottom: 0, // Align to the bottom
        left: 0, // Align to the left
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`, // Add a gradient overlay
        backgroundSize: 'cover', // Cover the container
        //zIndex: 1, // Place behind other content
    },
    container: {
        padding: '70px',
        backgroundColor: 'rgba(10, 77, 77, 0.3)', // Increased transparency
        margin: '20px auto',
        borderRadius: '25px',
        boxShadow: '0 4px 5px rgba(0, 0, 0, 0.2)', // Softer shadow for a lifted look
        backdropFilter: 'blur(10px)', // Blur to create the frosted glass effect
        WebkitBackdropFilter: 'blur(10px)', // For Safari support
        border: '1px solid rgba(255, 255, 255, 0.3)', // Slightly more visible white border
        width: '90%',
        maxWidth: '1000px',
        position: 'relative',
        zIndex: 1, // Ensure this is lower than the NavTaskBar
    },
    heading: {
        fontSize: '1.5em',
        textAlign: 'center',
        color: 'teal',
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
    },
    buttonActive: {
        marginTop: '20px',
        Width: '250px',
        padding: '10px 20px',
        backgroundColor: '#008080',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    buttonDisabled: {
        marginTop: '20px',
        padding: '10px 20px',
        Width: '250px',
        backgroundColor: '#ccc',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'not-allowed',
        fontWeight: 'bold',
    },
    error: {
        color: 'red',
        textAlign: 'center',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
        
    },
};

export default TermsAndConditions;
