import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard';
import About from './About';
import Registration from './Registration';
import Login from './Login';
import Showname from './showname';
import UserDashboard from './userdashboard';
import { AuthProvider } from './AuthProvider'; 
import PersonalDash from './PersonalDash';
import SalesReport from './SalesReport';
import CustomerRegistrationForm from './CustomerRegistrationForm';
import Products from './products';
import TermsAndConditions from './TermsAndConditions';
import SendMail from './SendMail';
import Contact from './contact';
import Activities from './Activities';
import BuyNow from './BuyNow';
import AdminBranchManagement from './AdminBranchManagement';
import ZoomPersist from './ZoomPersist'; // Import ZoomPersist
import UserList from './UserList';
import DirectSalesReport from './DirectSalesReport';
import SalesPointReport from './SalesPointReport';
import ChangePassword from './Changepassword';
import GSix from './GSix';
import Education from './Education';
import UpdateInfo from './UpdateInfo';





function App() {
  return (
    <Router>
      <AuthProvider> {/* Wrap your routes with AuthProvider */}
        <ZoomPersist> {/* Wrap your routes with ZoomPersist */}
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/about" element={<About />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/login" element={<Login />} />
            <Route path="/userdashboard" element={<UserDashboard />} />
            <Route path="/showname" element={<Showname />} />
            <Route path="/personaldash" element={<PersonalDash />} />
            <Route path="/salesreport" element={<SalesReport />} />
            <Route path="/CustomerRegistrationForm" element={<CustomerRegistrationForm />} />
            <Route path="/products" element={<Products />} />
            <Route path="/termsandconditions" element={<TermsAndConditions />} />
            <Route path="/sendmail" element={<SendMail />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/buynow" element={<BuyNow />} />
            <Route path="/adminbranchmanagement" element={<AdminBranchManagement />} />
            <Route path="userlist" element={<UserList/>}/>
            <Route path="/directsalesreport" element={<DirectSalesReport/>} />
            <Route path="/salespointreport" element={<SalesPointReport/>}/>
            <Route path="/changepassword" element={<ChangePassword/>}/>
            <Route path="/gsix" element={<GSix/>}/>
            <Route path="/Education" element={<Education/>}/>
            <Route path="/updateinfo" element={<UpdateInfo/>}/>
          </Routes>
        </ZoomPersist>
      </AuthProvider>
    </Router>
  );
}

export default App;
