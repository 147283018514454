import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image
import backgroundImage from './teal background.jpg'; // Import the background image

// Import cosmetic images
import cosmetic1 from './cosmetic1.jpg';
import cosmetic2 from './cosmetic2.jpg';
import cosmetic3 from './cosmetic3.jpg';
import cosmetic4 from './cosmetic4.jpg';
import cosmetic5 from './cosmetic5.jpg';

function BuyNow() {
    const [cart, setCart] = useState([]);
    const [ratings, setRatings] = useState({
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
    });
    const navigate = useNavigate();

    // Add product to cart
    const addToCart = (product) => {
        setCart([...cart, product]);
    };

    // Update rating for a specific product
    const handleRatingChange = (productId, rating) => {
        setRatings((prevRatings) => ({
            ...prevRatings,
            [productId]: rating,
        }));
    };

    // Star rating component
    const StarRating = ({ rating, productId }) => {
        const handleClick = (newRating) => {
            handleRatingChange(productId, newRating);
        };

        const stars = Array(5).fill(false).map((_, index) => index < rating);
        return (
            <div style={styles.rating}>
                {stars.map((filled, index) => (
                    <span
                        key={index}
                        onClick={() => handleClick(index + 1)}
                        style={filled ? styles.filledStar : styles.emptyStar}
                    >
                        ★
                    </span>
                ))}
            </div>
        );
    };

    // Product details
    const products = [
        { id: 1, image: cosmetic1, name: 'Cosmetic 1', details: 'High-quality cosmetic product.' },
        { id: 2, image: cosmetic2, name: 'Cosmetic 2', details: 'Luxurious and premium quality.' },
        { id: 3, image: cosmetic3, name: 'Cosmetic 3', details: 'Affordable and reliable.' },
        { id: 4, image: cosmetic4, name: 'Cosmetic 4', details: 'Great for everyday use.' },
        { id: 5, image: cosmetic5, name: 'Cosmetic 5', details: 'Top-rated and best seller.' },
    ];

    return (
        <div style={styles.wrapper}>
        {/* Background Container */}
        <div style={styles.background} />
            {/* Navigation Bar */}
            <nav style={styles.nav}>
                <ul style={styles.navList}>
                    <li style={styles.navItem}>
                        <img src={logo} alt="Suplex Asia Logo" style={styles.logo} /> {/* Logo Image */}
                        <span style={styles.navText}>SUPLEX Ashia - Information System</span> {/* Text with common teal */}
                    </li>
                </ul>
            </nav>

            {/* Task Bar */}
            <div style={styles.taskBar}>
                <button style={styles.button} onClick={() => navigate("/")}>HOME</button>
                <button style={styles.button} onClick={() => navigate("/products")}>PRODUCTS</button>
                <button style={styles.button} onClick={() => navigate("")}>ACTIVITIES</button>
                <button style={styles.button} onClick={() => navigate("/buy-now")}>BuyNow</button>
                <button style={styles.button} onClick={() => navigate("/login")}>Login</button>
            </div>

            {/* Main Content */}
            <header style={styles.header}>
                <h1 style={styles.heading}>Buy Now</h1>
                <div style={styles.productsContainer}>
                    {products.map((product) => (
                        <div key={product.id} style={styles.productCard}>
                            <img src={product.image} alt={product.name} style={styles.productImage} />
                            <h2 style={styles.productName}>{product.name}</h2>
                            <StarRating rating={ratings[product.id] || 0} productId={product.id} />
                            <p style={styles.productDetails}>{product.details}</p>
                            <label style={styles.addButton} onClick={() => addToCart(product)}>Add to Cart</label>
                        </div>
                    ))}
                </div>
            </header>

            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    © 2024 Suplex Ashia. All rights reserved.
                </div>
            </footer>
        </div>
    );
}

const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
      //minHeight: '100vh', // Ensure the container covers the full viewport height
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between', // Distribute content evenly
      alignItems: 'center', // Center horizontally
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      paddingBottom: '50px', // Space for any footer if needed
      width: '1620px', // Fixed width to enforce desktop view
      overflowX: 'hidden', // Prevent horizontal scrollbars
    },
    
    nav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#FFFAFA',
        marginLeft: '60px',
        marginRight: '60px',
        color: 'black',
        height: '120px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        padding: '0 20px', // Add horizontal padding
      },
    navList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center', // Align items to the center vertically
    },
    navItem: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '1em', // Adjust margin for spacing
    },
    logo: {
      height: '80px', // Increased height of the logo
      marginRight: '10px',
    },
    navText: {
      backgroundColor: 'transparent', // Removed background color
      color: '#008080', // Dark teal for text
      fontSize: '1.7em', // Default font size
      fontWeight: 'bold',
      // Media query for tablets and small devices
      '@media (max-width: 768px)': {
        fontSize: '1.2em', // Smaller font size for tablets
      },
      // Media query for mobile devices
      '@media (max-width: 480px)': {
        fontSize: '0.1em', // Smaller font size for mobile phones
      },
    },
    wrapper: {
      fontFamily: 'Arial, sans-serif',
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      position: 'relative', // Position relative to contain absolutely positioned children
      minHeight: '100vh', // Ensure wrapper covers the full viewport height
      background: 'linear-gradient(to bottom, #E0FFFF 70%, #008080)', // Gradient from light teal to dark teal
  },
  taskBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1em 60px', // Apply consistent margin with left and right included
    backgroundColor: '#008080',
    padding: '0.5em 20px',
    borderRadius: '8px',
    flexWrap: 'wrap',
    position: 'relative',
  },
  
  menuContainer: {
    position: 'absolute', // Position relative to taskBar
    left: '1em', // Add space from the left edge of taskBar
    top: '50%', // Center vertically
    transform: 'translateY(-50%)', // Center vertically
    zIndex: 1, // Ensure it is above other elements
    display: 'flex', // Ensure menuButton is centered
    alignItems: 'center',
    justifyContent: 'center',
  },
  
  menuButton: {
    backgroundColor: '#008080', // Teal color
    color: '#E0FFFF', // Light teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '0.6em 0.6em', // Adjusted padding
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    margin: '0 1em', // Add space on left and right sides
  },
  
  menuLine: {
    width: '20px', // Line width
    height: '2px', // Line height
    backgroundColor: '#E0FFFF', // Light teal color
    margin: '2px 0',
  },
  
  menuDropdown: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: '#E0FFFF', // Light teal color
    border: 'none', // Removed border
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    minWidth: '100px',
    top: '100%',
    left: '0',
    padding: '0.5em',
  },
  
  menuDropdownItem: {
    padding: '0.3em 0.6em', // Padding
    backgroundColor: '#E0FFFF', // Light teal color
    color: '#008080', // Dark teal text
    fontWeight: 'bold',
    border: 'none', // Removed border
    cursor: 'pointer',
    borderRadius: '6px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '0.25em 0', // Margin
    textAlign: 'center',
    fontSize: '0.9em', // Font size
  },
  
    button: {
      padding: '0.5em 1.5em', // Reduced padding
      backgroundColor: '#E0FFFF', // Very light teal
      color: '#008080', // Dark teal text
      fontWeight: 'bold',
      border: 'none', // Removed border
      cursor: 'pointer',
      margin: '0.5em',
      borderRadius: '5px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      minWidth: '50px', // Reduced min width
      fontSize: '0.9em', // Reduced font size
      textAlign: 'center',
    },
    dropdownContainer: {
      position: 'relative',
      display: 'inline-block',
    },
    dropdown: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      backgroundColor: '#E0FFFF', // Very light teal
      border: 'none', // Removed border
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      zIndex: 1,
      minWidth: '100px',
      top: '100%',
      left: 0,
      padding: '0.5em',
    },
    dropdownItem: {
      padding: '0.3em 1.5em', // Reduced padding
      backgroundColor: '#E0FFFF', // Very light teal
      color: '#008080', // Dark teal text
      fontWeight: 'bold',
      border: 'none', // Removed border
      cursor: 'pointer',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      margin: '0.5em 0',
      textAlign: 'center',
      fontSize: '0.9em', // Reduced font size
     
    },
    header: {
        marginTop: '2em',
        textAlign: 'center',
    },
    heading: {
        color: '#008080',
        fontSize: '2em',
    },
    productsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '20px',
    },
    productCard: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        maxWidth: '200px',
        textAlign: 'center',
        marginBottom: '20px',
    },
    productImage: {
        width: '100%',
        borderRadius: '8px',
    },
    productName: {
        color: '#008080',
        fontSize: '1.2em',
        margin: '10px 0',
    },
    productDetails: {
        fontSize: '0.9em',
        marginBottom: '10px',
    },
    addButton: {
        backgroundColor: '#008080',
        color: '#E0FFFF',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        padding: '0.5em 1em',
        fontWeight: 'bold',
    },
    rating: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0',
    },
    filledStar: {
        color: '#FFD700',
        fontSize: '1.2em',
        cursor: 'pointer',
    },
    emptyStar: {
        color: '#ddd',
        fontSize: '1.2em',
        cursor: 'pointer',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#E0FFFF',
        textAlign: 'center',
        padding: '10px',
        position: 'fixed',
        bottom: '0',
        width: '100%',
    },
    footerContainer: {
        maxWidth: '1200px',
        margin: '0 auto',
    },
};

export default BuyNow;
