import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import NavTaskBar from './NavTaskBar';
import imagePath from './bancsuplex.jpg';

const Education = ({
    handleAbout,
    handleProducts,
    handlePromotionalPath,
    handleContact,
    handleLogin,
    dropdownItems,
    openDropdown,
    setOpenDropdown,
    hoverIndex,
    setHoverIndex,
    showMenu,
    setShowMenu,
    dropdownRef,
    menuRef,
    navigate,
}) => {
    const location = useLocation();
    const { username } = location.state || {};
    const [userName, setUserName] = useState(''); // Initialize userName state

    // Fetch and set the userName from Firebase
    useEffect(() => {
        console.log('Username received:', username); // Debugging: Check if username is being received correctly
        if (username) {
            const db = getDatabase();
            const usersRef = ref(db, 'users');
            onValue(usersRef, (snapshot) => {
                const users = snapshot.val();
                console.log('Fetched users:', users); // Debugging: Check the users fetched from Firebase
                if (users) {
                    const user = Object.values(users).find(user => user.username === username);
                    if (user) {
                        console.log('User found:', user, username); // Debugging: Check the user found with the username
                        setUserName(user.name);
                    } else {
                        console.log('No user found with username:', username); // Debugging: If no user is found
                        setUserName('User not found');
                    }
                } else {
                    console.log('No users data available in Firebase.');
                    setUserName('No user data available');
                }
            }, (error) => {
                console.error('Error fetching data from Firebase:', error); // Debugging: Catch any Firebase errors
                setUserName('Error loading user');
            });
        }
    }, [username]);

    return (
        <div style={styles.wrapper}>
            <NavTaskBar 
                username={username} 
                userName={userName}  // Pass userName to NavTaskBar
                handleAbout={handleAbout}
                handleProducts={handleProducts}
                handlePromotionalPath={handlePromotionalPath}
                handleContact={handleContact}
                handleLogin={handleLogin}
                dropdownItems={dropdownItems}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                hoverIndex={hoverIndex}
                setHoverIndex={setHoverIndex}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                dropdownRef={dropdownRef}
                menuRef={menuRef}
                navigate={navigate}
            />
            <div style={styles.container}>
                <h2 style={styles.header}>Education</h2>
                <p style={styles.developmentMessage}>This site is under development.</p>
            </div>
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Ashia. All rights reserved.</p>
                </div>
            </footer>
            <div style={styles.imageContainer} />
        </div>
    );
};

const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh',
        backgroundColor: '#E0FFFF',
        width: '1620px', // Fixed width for non-responsiveness
    },
    imageContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '300px',
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`,
        backgroundSize: 'cover',
    },
    container: {
        padding: '20px',
        backgroundColor: 'rgba(224, 255, 255, 0.7)',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        margin: '20px auto',
        maxWidth: '900px',
        position: 'relative',
        zIndex: 1,
    },
    header: {
        fontSize: '1.5em',
        textAlign: 'center',
        color: 'teal',
    },
    developmentMessage: {
        fontSize: '1em',
        textAlign: 'center',
        color: 'teal',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1,
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
        zIndex: 1,
    },
};

export default Education
;
