// PersonalDash.jsx
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import logo from './suplex-asia-logo.png'; // Ensure the path is correct
import NavBar from './NavTaskBar'; // Import NavBar component
import imagePath from './bancsuplex.jpg'; // Import the same background image

const PersonalDash = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { username } = location.state || {}; // Extract placementTop from location.state
    const [userName, setUserName] = useState(''); // State to store the user's name
    const [openDropdown, setOpenDropdown] = useState(null); // Track which dropdown is open
    const [hoverIndex, setHoverIndex] = useState(null);
    const [showMenu, setShowMenu] = useState(false); // State for the hamburger menu
    const dropdownRef = useRef(null); // Ref for the dropdown
    const menuRef = useRef(null); // Ref for the menu

    useEffect(() => {
        console.log('PlacementTop received:', username); // Debugging: Check if placementTop is being received correctly
        if (username) {
            const db = getDatabase();
            const usersRef = ref(db, 'users');
            onValue(usersRef, (snapshot) => {
                const users = snapshot.val();
                console.log('Fetched users:', users); // Debugging: Check the users fetched from Firebase
                if (users) {
                    const user = Object.values(users).find(user => user.username === username);
                    if (user) {
                        console.log('User found:', user, username); // Debugging: Check the user found with the placementTop
                        setUserName(user.name);
                    } else {
                        console.log('No user found with placementTop:', username); // Debugging: If no user is found
                        setUserName('User not found');
                    }
                } else {
                    console.log('No users data available in Firebase.');
                    setUserName('No user data available');
                }
            }, (error) => {
                console.error('Error fetching data from Firebase:', error); // Debugging: Catch any Firebase errors
                setUserName('Error loading user');
            });
        }
    }, [username]);

    const handleChangePassword = () => {
        console.log("Change Password action");
    };

    const handleUpdateInfo = () => {
        console.log("Update Info action");
    };

    const handleInvoiceReport = () => {
        console.log("Invoice Report action");
    };

    const handleAbout = () => {
        navigate('/about'); // Navigate to About page
    };

    const handleProducts = () => {
        console.log("Products action");
    };

    const handlePromotionalPath = () => {
        console.log("Promotional Path action");
    };

    const handleContact = () => {
        console.log("Contact action");
    };

    const handleLogin = () => {
        navigate('/login'); // Navigate to login page
    };

    const dropdownItems = [
        { text: "Change Password", action: handleChangePassword },
        { text: "Update Info", action: handleUpdateInfo },
        { text: "Invoice Report", action: handleInvoiceReport }
    ];

    const handleViewReport = () => {
        navigate('/termsandconditions', { state: { username, userName } });
    };

    return (
        <div style={styles.wrapper}>
            <NavBar 
                username={username} 
                userName={userName} 
                handleAbout={handleAbout}
                handleProducts={handleProducts}
                handlePromotionalPath={handlePromotionalPath}
                handleContact={handleContact}
                handleLogin={handleLogin}
                dropdownItems={dropdownItems}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                hoverIndex={hoverIndex}
                setHoverIndex={setHoverIndex}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                dropdownRef={dropdownRef}
                menuRef={menuRef}
                navigate={navigate}
            />
            <div style={styles.container}>
                <main style={styles.main}>
                    <p style={styles.mainText2}>Before you give up! Remember</p>
                    <p style={styles.mainText}></p>
                    <p style={styles.mainText3}>Why you started!</p>
                    
                    <p style={styles.mainText1}>IMPOSSIBLE IS</p>
                    <p style={styles.mainText1}>POSSIBLE</p>

                </main>
            </div>
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    © 2024 Suplex Ashia. All rights reserved.
                </div>
            </footer>
            <div style={styles.imageContainer} />
        </div>
    );
};

const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh', // Ensure content height is maintained
       // height: 'auto', // Content determines the height
        background: '#E0FFFF', // Light teal background color
       width: '1635px', // Set to 100% for responsiveness
        maxWidth: '1635px', // Optional max-width for large screens
    },
    
    imageContainer: {
        width: '100%', // Full width of the wrapper
        height: '300px', // Fixed height for the image
        position: 'absolute', // Position it at the bottom of wrapper
        bottom: 0, // Align to the bottom
        left: 0, // Align to the left
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`, // Add a gradient overlay
        backgroundSize: 'cover', // Cover the container
        //zIndex: 1, // Place behind other content
    },
    
    container: {
        padding: '20px',
        backgroundColor: 'rgba(10, 77, 77, 0.3)', // Increased transparency
        margin: '20px auto',
        borderRadius: '25px',
        boxShadow: '0 4px 5px rgba(0, 0, 0, 0.2)', // Softer shadow for a lifted look
        backdropFilter: 'blur(10px)', // Blur to create the frosted glass effect
        WebkitBackdropFilter: 'blur(10px)', // For Safari support
        border: '1px solid rgba(255, 255, 255, 0.3)', // Slightly more visible white border
        width: '90%',
        maxWidth: '10000px',
        position: 'relative',
        zIndex: 1, // Ensure this is lower than the NavTaskBar
    },
    main: {
        backgroundColor: 'rgba(224, 255, 255, 0.7)',
        padding: '20px',
        maxWidth: '600px', // Adjusted maxWidth for the main content
        margin: '0 auto',  // Centers the main element horizontally
        borderRadius: '20px',
        textAlign: 'center',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Added box shadow for depth
    },
    mainText2: {
        color: '#008080',
        fontSize: '30px',
        fontStyle: 'italic', // Set the font style to italic
        marginBottom: '1px', // Space below the italic text
    },
    mainText3: {
        color: '#008080',
        fontSize: '30px',
        fontStyle: 'italic', // Set the font style to italic
        marginBottom: '100px', // Space below the italic text
    },
    mainText1: {
        color: '#008080',
        fontSize: '20px',
        fontWeight: 'bold', // Set the font weight to bold
        marginTop: '20px', // Space above the bold text (if needed)
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
        
    },
};

export default PersonalDash;
