import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image

const NavTaskBar = ({ userName, username }) => {
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [showSalesDropdown, setShowSalesDropdown] = useState(false);
  const [showSpecialTaskDropdown, setShowSpecialTaskDropdown] = useState(false)  
  const menuRef = useRef(null);
  const salesDropdownRef = useRef(null);
  const specialTaskDropdownRef = useRef(null);

  // Close dropdowns when clicking outside
  const handleClickOutside = (event) => {
    if (
      menuRef.current && !menuRef.current.contains(event.target) &&
      salesDropdownRef.current && !salesDropdownRef.current.contains(event.target) &&
      specialTaskDropdownRef.current && !specialTaskDropdownRef.current.contains(event.target)
    ) {
      setShowMenu(false);
      setShowSalesDropdown(false);
      setShowSpecialTaskDropdown(false);
    }
  };
  // Toggle menu dropdown
  const toggleMenu = () => {
    setShowMenu(!showMenu);
    if (showSalesDropdown || showSpecialTaskDropdown) {
      setShowSalesDropdown(false);
      setShowSpecialTaskDropdown(false);
    }
  };

  // Toggle sales dropdown
  const toggleSalesDropdown = () => {
    setShowSalesDropdown(!showSalesDropdown);
    if (showMenu || showSpecialTaskDropdown) {
      setShowMenu(false);
      setShowSpecialTaskDropdown(false);
    }
  };

  // Toggle special task dropdown
  const toggleSpecialTaskDropdown = () => {
    setShowSpecialTaskDropdown(!showSpecialTaskDropdown);
    if (showMenu || showSalesDropdown) {
      setShowMenu(false);
      setShowSalesDropdown(false);
    }
  };

  // Action handlers
  const handleAbout = () => {
    navigate('/about'); // Navigate to About page
  };

  const handleProducts = () => {
    console.log("Products action");
  };

  const handlePromotionalPath = () => {
    console.log("Promotional Path action");
  };

  const handleContact = () => {
    console.log("Contact action");
  };

  const handleLogin = () => {
    navigate('/login'); // Navigate to Login page
  };

  const handleSalesPointReport = () => {
    navigate('/salespointreport', { state: { username } }); // Navigate to User List
  };
  const handleSpecialTaskChangePassword = () => {
    navigate('/changepassword', { state: { username } }); // Navigate to User List
  };
  const handleSpecialTaskUpdateInfo = () => {
    navigate('/updateinfo', { state: { username } }); // Navigate to User List
  };

  

  const handleSalesUnitPoints = () => {
    navigate('/userlist', { state: { username } }); // Navigate to User List
  };
  const handleDirectSalesReport = () => {
    navigate('/directsalesreport', { state: { username } }); // Navigate to User List
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear local storage
    navigate("/"); // Navigate to home
  };
 

  const menuItems = [
    { text: "About", action: handleAbout },
    { text: "Products", action: handleProducts },
    { text: "Promotional Path", action: handlePromotionalPath },
    { text: "Contact", action: handleContact },
    { text: "Login", action: handleLogin }
  ];

  const salesReportItems = [
    { text: "Sales Point Report", action: handleSalesPointReport },
    { text: "Direct Sales Report", action: handleDirectSalesReport },
    { text: "Sales Unit Points", action: handleSalesUnitPoints }
  ];
  const specialTaskItems = [
    { text: "Change Password", action: handleSpecialTaskChangePassword },
    { text: "Update Info", action: handleSpecialTaskUpdateInfo },
    { text: "Invoice Report"}
  ];

  // Attach click event listener
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Navigation Bar */}
      <div style={styles.nav}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '150px', marginRight: '10px'}} />
          <span style={styles.navText}>SUPLEX Ashia</span>
          <span style={{ color: 'teal', fontSize: '1.5em', fontWeight: 'bold', paddingLeft: '10px' , marginBottom: '25px'}}>
             InformationSystem
          </span>
        </div>
        <p style={{ margin: '0 5px', textAlign: 'center', fontSize: '1.0em', fontWeight: 'bold', color: 'teal' }}>
          Currency: LKR (SL Rupee)
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '20px' }}>
        <label
            style={{ marginRight: '10px', marginTop: '2px', padding: '12px', backgroundColor: '#20B2AA', color: '#E0FFFF', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
            onClick={handleLogout}
          >
            Logout
          </label>
          <p style={{ margin: '0', color: '#008080', fontSize: '1.0em', fontWeight: 'bold', textAlign: 'right' }}>Welcome</p>
          <p style={{ margin: '0', color: '#008080', fontSize: '1.0em', fontWeight: 'bold' }}>
            {userName || 'Loading..'}!
          </p>
          {username && (
            <p style={{ margin: '0', color: '#008080', fontSize: '1.0em', fontWeight: 'bold' }}>
              ({username})
            </p>
          )}
        </div>
      </div>
      
      {/* Task Bar */}
      <div style={styles.taskBar}>
        <div style={styles.menuContainer} ref={menuRef}>
          <button style={styles.menuButton} onClick={toggleMenu}>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
          </button>
          {showMenu && (
            <div style={styles.menuDropdown}>
              {menuItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    ...styles.menuDropdownItem,
                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#008080' // Dark teal on hover
                  }}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={item.action} // Call the respective action
                >
                  {item.text}
                </button>
              ))}
            </div>
          )}
        </div>
        <label style={styles.button} onClick={() => navigate("/")}>HOME</label>
        <div style={styles.salesDropdownContainer} ref={salesDropdownRef}>
          <label style={styles.button} onClick={toggleSalesDropdown}>
            Sales Report
          </label>
          {showSalesDropdown && (
            <div style={styles.salesDropdown}>
              {salesReportItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    ...styles.menuDropdownItem,
                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#008080' // Dark teal on hover
                  }}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={item.action} // Call the respective action
                >
                  {item.text}
                </button>
              ))}
            </div>
          )}
        </div>
        <label style={styles.button} onClick={() => navigate('/Education', { state: { username } })}>Education</label>
        <label style={styles.button} onClick={() => navigate('/activities', { state: { username } })}>Commision History</label>
        <label style={styles.button} onClick={() => navigate('/termsandconditions', { state: { username } })}>Registration</label>
        <label style={styles.button} onClick={() => navigate('/gsix', { state: { username } })}>G6</label>
        <div style={styles.salesDropdownContainer} ref={specialTaskDropdownRef}>
          <label style={styles.button} onClick={toggleSpecialTaskDropdown}>
            Special Task
          </label>
          {showSpecialTaskDropdown && (
            <div style={styles.specialDropdown}>
              {specialTaskItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    ...styles.menuDropdownItem,
                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#008080' // Dark teal on hover
                  }}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                  onClick={item.action} // Call the respective action
                >
                  {item.text}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '16px',
    marginLeft: '60px',
    marginRight: '60px',
    color: 'black',
    height: '120px',
    padding: '0 20px', // Fixed horizontal padding
    width: '1450px', // Fixed width for non-responsiveness
  },
  taskBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1em 60px', // Consistent margin
    backgroundColor: '#008080',
    padding: '0.5em 20px',
    borderRadius: '16px',
    width: '1450px', // Fixed width for non-responsiveness
    position: 'relative',
  },
  menuContainer: {
    position: 'absolute',
    left: '1em',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButton: {
    width: '50px',
    height: '40px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
    margin: '0',
  },
  menuLine: {
    width: '80%',
    height: '5px',
    background: '#FFFFFF',
    margin: '4px 0',
    borderRadius: '2px',
    transition: '0.3s',
  },
  menuDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    color: '#FFFFFF',
    borderRadius: '5px',
    zIndex: 9,
    width: '200px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  menuDropdownItem: {
    width: '100%',
    padding: '10px 15px',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    transition: '0.3s',
  },
  salesDropdownContainer: {
    position: 'relative',
    zIndex: 10,
  },
  salesDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    color: '#FFFFFF',
    borderRadius: '5px',
    zIndex: 9,
    width: '200px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  specialDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    color: '#FFFFFF',
    borderRadius: '5px',
    zIndex: 9,
    width: '200px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  button: {
    padding: '0.5em 1.5em',
    backgroundColor: '#E0FFFF',
    color: '#008080',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    margin: '0.5em',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '50px',
    fontSize: '0.9em',
    textAlign: 'center',
  },
  navText: {
    color: '#008080',
    fontSize: '1.5em',
    marginBottom: '26px',
    fontWeight: 'bold',
  },
};

export default NavTaskBar;
