import React, { useState, useEffect } from 'react';
import { database, ref, get, child, set } from './firebase'; // Import Firebase functions
import backgroundImage from './bancsuplex.jpg'; // Import the background image

const AdminPortal = () => {
    const [presenters, setPresenters] = useState([]);
    const [deliveryCodes, setDeliveryCodes] = useState([]);
    const [users, setUsers] = useState([]); // State for users
    const [newPresenter, setNewPresenter] = useState('');
    const [newDeliveryCode, setNewDeliveryCode] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch admin config
                const snapshot = await get(child(ref(database), 'adminConfig'));
                if (snapshot.exists()) {
                    const configData = snapshot.val();
                    setPresenters(configData.presenters || []);
                    setDeliveryCodes(configData.deliveryCodes || []);
                }

                // Fetch all users
                const usersSnapshot = await get(child(ref(database), 'users')); // Adjust path as needed
                if (usersSnapshot.exists()) {
                    const usersData = usersSnapshot.val();
                    setUsers(Object.values(usersData));
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const addPresenter = async () => {
        if (newPresenter.trim()) {
            const updatedPresenters = [...presenters, newPresenter];
            setPresenters(updatedPresenters);
            await set(ref(database, 'adminConfig/presenters'), updatedPresenters);
            setNewPresenter(''); // Clear the input field
        }
    };

    const addDeliveryCode = async () => {
        if (newDeliveryCode.trim()) {
            const updatedDeliveryCodes = [...deliveryCodes, newDeliveryCode];
            setDeliveryCodes(updatedDeliveryCodes);
            await set(ref(database, 'adminConfig/deliveryCodes'), updatedDeliveryCodes);
            setNewDeliveryCode(''); // Clear the input field
        }
    };

    const deletePresenter = async (index) => {
        const updatedPresenters = presenters.filter((_, i) => i !== index);
        setPresenters(updatedPresenters);
        await set(ref(database, 'adminConfig/presenters'), updatedPresenters);
    };

    const deleteDeliveryCode = async (index) => {
        const updatedDeliveryCodes = deliveryCodes.filter((_, i) => i !== index);
        setDeliveryCodes(updatedDeliveryCodes);
        await set(ref(database, 'adminConfig/deliveryCodes'), updatedDeliveryCodes);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Upper body with light teal background */}
            <div style={{ flex: '1', backgroundColor: '#e0f7f7', padding: '20px', marginBottom: '0' }}>
                <h2>PRESENTER AND DELIVERY CODE ADMIN PORTAL</h2>

                <div>
                    <input
                        type="text"
                        value={newPresenter}
                        onChange={(e) => setNewPresenter(e.target.value)}
                        placeholder="Add new presenter"
                    />
                    <button onClick={addPresenter} style={{ width: '150px', padding: '5px', marginBottom: '10px' }}>Add Presenter</button>
                </div>

                <div>
                    <input
                        type="text"
                        value={newDeliveryCode}
                        onChange={(e) => setNewDeliveryCode(e.target.value)}
                        placeholder="Add new delivery code"
                    />
                    <button onClick={addDeliveryCode} style={{ width: '150px', padding: '5px', marginBottom: '10px' }}>Add Delivery Code</button>
                </div>

                <div style={{ marginTop: '20px', padding: '10px', backgroundColor: '#f0f0f0' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '48%' }}>
                            <h3>Manage Presenters</h3>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'left' }}>Presenter</th>
                                        <th style={{ textAlign: 'center' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {presenters.map((presenter, index) => (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'left' }}>{presenter}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button onClick={() => deletePresenter(index)} style={{ width: '100px', padding: '4px' }}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ width: '48%' }}>
                            <h3>Manage Delivery Codes</h3>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'left' }}>Delivery Code</th>
                                        <th style={{ textAlign: 'center' }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {deliveryCodes.map((code, index) => (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'left' }}>{code}</td>
                                            <td style={{ textAlign: 'center' }}>
                                                <button onClick={() => deleteDeliveryCode(index)} style={{ width: '100px', padding: '4px' }}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bottom section with background image directly touching the teal background */}
            <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', height: '200px', backgroundPosition: 'center' }}>
                {/* Optional content can be added here if necessary */}
            </div>
        </div>
    );
};

export default AdminPortal;
