import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image
import backgroundImage from './teal background.jpg'; // Import the background image
import registernote1 from './resgisternote1.jpg'; // Import registration image 1
import registernote2 from './registernote2.jpg'; // Import registration image 2
import Navbar from './Navbar'; // Import Navbar component
import imagePath from './bancsuplex.jpg'; // Update the image path accordingly

function About() {
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(null);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const handleMouseEnter = (index) => {
        setHoverIndex(index);
    };

    const handleMouseLeave = () => {
        setHoverIndex(null);
    };

    const dropdownItems = ["Change Password", "Update Info", "Invoice Report"];

    return (
        <div style={styles.wrapper}>
            {/* Navigation Bar */}
            <Navbar logo={logo} title="SUPLEX Ashia - Information System" />

            {/* Main Content */}
            <div style={styles.mainContent}>
                <div style={styles.contentContainer}>
                    {/* Vision */}
                    <section style={styles.section}>
                        <h2>Vision</h2>
                        <p>Our vision is to be the leading provider of innovative solutions in the industry.</p>
                    </section>
                    {/* Mission */}
                    <section style={styles.section}>
                        <h2>Mission</h2>
                        <p>Our mission is to deliver high-quality products that bring value to our customers.</p>
                    </section>
                    {/* Objectives */}
                    <section style={styles.section}>
                        <h2>Objectives</h2>
                        <ul style={styles.objectivesList}>
                            <li>Provide excellent customer service.</li>
                            <li>Innovate continuously to meet market needs.</li>
                            <li>Ensure sustainable growth and profitability.</li>
                        </ul>
                    </section>
                    {/* Chairman's Message */}
                    <section style={styles.section}>
                        <h2>Chairman's Message</h2>
                        <p>Dear valued stakeholders, we are committed to driving progress and achieving excellence in all our endeavors.</p>
                    </section>
                    {/* Registration Images */}
                    <div style={styles.imageWrapper}>
                        <div style={styles.imageContainer}>
                            <img src={registernote1} alt="Register Note 1" style={styles.image} />
                            <img src={registernote2} alt="Register Note 2" style={styles.image} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Background Image at the Bottom */}
            <div style={styles.backgroundImageContainer}>
                <img src={imagePath} alt="Background" style={styles.backgroundImage} />
            </div>

            {/* Footer */}
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Ashia. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh',
        background: `linear-gradient(to bottom, #E0FFFF 70%, transparent), url(${imagePath})`, // Light teal on top, transparent at the bottom
        backgroundPosition: 'bottom', // Position the background image at the bottom
        backgroundSize: 'cover', // Cover the full container
        backgroundRepeat: 'no-repeat',
        height: '100%', // Fixed height for the background image section
        width: '1620px', // Fixed width for non-responsiveness
    },
    mainContent: {
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
        minHeight: 'calc(100vh - 200px)', // Adjust height to center content vertically (subtract footer height)
        padding: '1em', // Add some padding
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '600px', // Set a max width for content
        margin: '0 auto', // Center content horizontally
    },
    section: {
        width: '100%',
        padding: '2em',
        textAlign: 'center',
        alignItems: 'center',
        backgroundColor: '#E0FFFF', // Light teal background for sections
        marginBottom: '1em',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        border: '2px solid #008080', // Teal outline
    },
    objectivesList: {
        listStyleType: 'none', // Remove bullet points
        padding: 0, // Remove padding
        textAlign: 'left', // Align text to the left
    },
    imageWrapper: {
        display: 'flex',
        justifyContent: 'center', // Center images
        width: '100%',
        paddingTop: '1em', // Add some padding to the top of the images
    },
    imageContainer: {
        display: 'flex', // Use flex to align items in a row
        gap: '1em', // Space between images
        alignItems: 'center', // Align images in the center
    },
    image: {
        maxWidth: '300px', // Reduced max width
        height: 'auto',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        border: '2px solid #008080', // Teal outline
    },
    backgroundImageContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '200px', // Adjust height as necessary
        overflow: 'hidden',
        zIndex: -1, // Send the background image behind other elements
    },
    backgroundImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1,
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
    },
};

export default About;
