import React, { useState, useRef,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './suplex-asia-logo.png'; // Import the logo image
import backgroundImage from './bancsuplex.jpg'; // Import the background image


function Contact() {
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [showMenu, setShowMenu] = useState(false); // State for the hamburger menu
  const menuRef = useRef(null); // Ref for the menu

  // Toggle dropdown visibility
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  // Define action handlers for menu items
  const handleAbout = () => {
    navigate('/about'); // Navigate to About page
  };

  const handleProducts = () => {
    console.log("Products action");
  };

  const handlePromotionalPath = () => {
    console.log("Promotional Path action");
  };

  const handleContact = () => {
    console.log("Contact action");
  };

  const handleLogin = () => {
    navigate('/login'); // Navigate to Login page
  };

  const menuItems = [
    { text: "About", action: handleAbout },
    { text: "Products", action: handleProducts },
    { text: "Promotional Path", action: handlePromotionalPath },
    { text: "Contact", action: handleContact },
    { text: "Login", action: handleLogin }
  ];

  return (
    <div style={styles.wrapper}>
    
      {/* Background Container */}
      <div style={styles.background} />

      {/* Navigation Bar */}
      <div style={styles.nav}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '150px', marginRight: '10px' }} />
          <span style={styles.navText}>SUPLEX Ashia</span>
          <span style={{ color: 'teal', fontSize: '1.5em', fontWeight: 'bold', paddingLeft: '10px' , marginBottom: '25px'}}>
             InformationSystem
          </span>
        </div>
      </div>

      {/* Task Bar */}
      <div style={styles.taskBar}>
        <div style={styles.menuContainer} ref={menuRef}>
          <button style={styles.menuButton} onClick={toggleMenu}>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
            <div style={styles.menuLine}></div>
          </button>
          {showMenu && (
            <div style={styles.menuDropdown}>
              {menuItems.map((item, index) => (
                <button
                  key={index}
                  style={{
                    ...styles.menuDropdownItem,
                    backgroundColor: hoverIndex === index ? '#20B2AA' : '#008080' // Dark teal on hover
                  }}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={item.action} // Call the respective action
                >
                  {item.text}
                </button>
              ))}
            </div>
          )}
        </div>
        <button style={styles.button} onClick={() => navigate("/")}>HOME</button> {/* Navigate to Home page */}
        <button style={styles.button} onClick={() => navigate("/products")}>PRODUCTS</button>
        <button style={styles.button} onClick={() => navigate("")}>ACTIVITIES</button>
        <button style={styles.button} onClick={() => navigate("/buynow")}>BUY NOW</button>
        <button style={styles.button} onClick={() => navigate("/contact")}>CONTACT</button>
      </div>
          <header style={styles.header}>
              <h1 style={styles.heading}>Contact Us</h1>
          </header>
          <div style={styles.contactFormContainer}>
              <form style={styles.contactForm}>
                  <label style={styles.label}>
                      Name:
                      <input type="text" name="name" style={styles.input} required />
                  </label>
                  <label style={styles.label}>
                      Email:
                      <input type="email" name="email" style={styles.input} required />
                  </label>
                  <label style={styles.label}>
                      Message:
                      <textarea name="message" style={styles.textarea} required></textarea>
                  </label>
                  <button type="submit" style={styles.submitButton}>Send</button>
              </form>
          </div>
          <div style={styles.imageContainer} />
          <footer style={styles.footer}>
              <div style={styles.footerContainer}>
                  © 2024 Suplex Ashia. All rights reserved.
              </div>
          </footer>
      </div>
    );
}

const styles = {
  wrapper: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    position: 'relative',
    minHeight: '100vh', // Keeps the wrapper at least the full view height
    height: 'auto', // Allow the content to determine height
    background: '#E0FFFF', // Light teal background color
    width: '1620px', // Fixed width for non-responsiveness
},

imageContainer: {
  width: '100%', // Full width of the viewport
  height: '300px', // Fixed height for the image
  //position: 'absolute', // Position it absolutely
  bottom: 0, // Align to the bottom
  left: 0, // Align to the left
  background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${backgroundImage}) no-repeat center`, // Add a gradient overlay
  backgroundSize: 'cover', // Ensure the image covers the container
  zIndex: -1, // Place it behind other content
},
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '16px',
    marginLeft: '60px',
    marginRight: '60px',
    color: 'black',
    height: '120px',
    padding: '0 20px', // Fixed horizontal padding
    width: '1450px', // Fixed width for non-responsiveness
  },
  taskBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1em 60px', // Consistent margin
    backgroundColor: '#008080',
    padding: '0.5em 20px',
    borderRadius: '16px',
    width: '1450px', // Fixed width for non-responsiveness
    position: 'relative',
  },
  menuContainer: {
    position: 'absolute',
    left: '1em',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuButton: {
    width: '50px',
    height: '40px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
    margin: '0',
  },
  menuLine: {
    width: '80%',
    height: '5px',
    background: '#FFFFFF',
    margin: '4px 0',
    borderRadius: '2px',
    transition: '0.3s',
  },
  menuDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    color: '#FFFFFF',
    borderRadius: '5px',
    zIndex: 9,
    width: '200px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  menuDropdownItem: {
    width: '100%',
    padding: '10px 15px',
    border: 'none',
    textAlign: 'left',
    cursor: 'pointer',
    transition: '0.3s',
  },
  salesDropdownContainer: {
    position: 'relative',
    zIndex: 10,
  },
  salesDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    color: '#FFFFFF',
    borderRadius: '5px',
    zIndex: 9,
    width: '200px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  specialDropdown: {
    position: 'absolute',
    top: '50px',
    left: '0',
    background: '#008080',
    color: '#FFFFFF',
    borderRadius: '5px',
    zIndex: 9,
    width: '200px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  button: {
    padding: '0.5em 1.5em',
    backgroundColor: '#E0FFFF',
    color: '#008080',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    margin: '0.5em',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '50px',
    fontSize: '0.9em',
    textAlign: 'center',
  },
  navText: {
    color: '#008080',
    fontSize: '1.5em',
    marginBottom: '26px',
    fontWeight: 'bold',
  },
  header: {
    marginTop: '2em',
    textAlign: 'center',
  },
  heading: {
    color: '#008080',
    fontSize: '2em',
  },
  contactFormContainer: {
    backgroundColor: '#E0FFFF',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    margin: '2em 0',
  },
  contactForm: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#E0FFFF',
  },
  label: {
    marginBottom: '10px',
  },
  input: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    marginBottom: '10px',
    backgroundColor: '#E0FFFF',
  },
  textarea: {
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    marginBottom: '10px',
    minHeight: '100px',
    backgroundColor: '#E0FFFF',
  },
  submitButton: {
    backgroundColor: '#008080',
    color: '#E0FFFF',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    padding: '10px',
    fontWeight: 'bold',
  },
  footer: {
    backgroundColor: '#008080',
    color: '#fff',
    textAlign: 'center',
    padding: '10px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
},
footerContainer: {
    margin: '0 auto',
    width: '80%',
},
};

export default Contact;
