import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getDatabase, ref, onValue, push } from 'firebase/database';
import NavTaskBar from './NavTaskBar'; // Adjust the import path as necessary
import imagePath from './bancsuplex.jpg'; // Update the image path accordingly

const database = getDatabase();

function SalesPointReport() {
    const location = useLocation();
    const { username } = location.state; // Retrieve username passed from the Login component
    const [userName, setUserName] = useState(''); // Add state for userName
    

    

  

    // Fetch and set the userName from Firebase
    useEffect(() => {
        if (username) {
            const db = getDatabase();
            const usersRef = ref(db, 'users');
            onValue(usersRef, (snapshot) => {
                const users = snapshot.val();
                if (users) {
                    const user = Object.values(users).find(user => user.username === username);
                    setUserName(user ? user.name : 'User not found');
                } else {
                    setUserName('No user data available');
                }
            }, (error) => {
                console.error('Error fetching data from Firebase:', error);
                setUserName('Error loading user');
            });
        }
    }, [username]);


    return (
        <div style={styles.wrapper}>
            <NavTaskBar 
                username={username} 
                userName={userName}
            />
            <div style={styles.container}>
                <h2 style={styles.heading}>Sales Point Report</h2>
                <div style={styles.tableContainer}>
                   
                    <table style={styles.tableTeal}>
                        
                    </table>
                    
                </div>
                
            </div>
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    © 2024 Suplex Ashia. All rights reserved.
                </div>
            </footer>
            <div style={styles.imageContainer} />
        </div>
    );
}

const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh', 
        backgroundColor: '#E0FFFF', 
        width: '1620px', // Fixed width for non-responsiveness
    },
    imageContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '300px', 
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`, 
        backgroundSize: 'cover',
    },
    container: {
        padding: '20px',
        backgroundColor: 'rgba(224, 255, 255, 0.7)',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        margin: '20px auto',
        maxWidth: '900px',
        position: 'relative',
        zIndex: 1,
    },
    heading: {
        fontSize: '1.5em',
        color: 'teal',
        margin: '20px 0',
        textAlign: 'center',
    },
    tableContainer: {
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)', 
        padding: '20px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginBottom: '20px', // Space between tables
    },
    tableHeader: {
        backgroundColor: '#E0FFFF',
        color: '#008080',
        padding: '10px',
        textAlign: 'left',
    },
    tableCell: {
        border: '1px solid #008080',
        padding: '10px',
        textAlign: 'left',
    },
    totalContainer: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: 'lightgray',
        borderRadius: '8px',
        width: '100%',
        textAlign: 'center',
    },
    totalText: {
        fontSize: '1.2em',
        color: 'teal',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 1,
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
        zIndex: 1,
    },
};

export default SalesPointReport;
