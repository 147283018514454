import React, { useState, useEffect } from 'react';
import { database, ref, get, child } from './firebase';

const DeliveryCodeComponent = () => {
    const [deliveryCodes, setDeliveryCodes] = useState([]);
    const [selectedDeliveryCode, setSelectedDeliveryCode] = useState('');

    // Function to fetch delivery codes from the database (adminConfig node)
    useEffect(() => {
        const fetchDeliveryCodes = async () => {
            try {
                const snapshot = await get(child(ref(database), 'adminConfig/deliveryCodes'));
                if (snapshot.exists()) {
                    const deliveryCodesData = snapshot.val();
                    setDeliveryCodes(deliveryCodesData || []); // Set delivery codes from adminConfig
                }
            } catch (error) {
                console.error('Error fetching delivery codes:', error);
            }
        };

        fetchDeliveryCodes();
    }, []);

    // Handle dropdown selection change
    const handleDropdownChange = (e) => {
        setSelectedDeliveryCode(e.target.value);
    };

    return (
        <div>
            {/* Render the dropdown with the fetched delivery codes */}
            <select name="deliveryCode" value={selectedDeliveryCode} onChange={handleDropdownChange}>
                <option value="" disabled>Select a delivery code</option>
                {deliveryCodes.map((code, index) => (
                    <option key={index} value={code}>
                        {code}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default DeliveryCodeComponent;
