import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getDatabase, ref, get, query, orderByChild, equalTo, limitToFirst, onValue } from 'firebase/database';
import NavTaskBar from './NavTaskBar';
import imagePath from './bancsuplex.jpg';

const DirectSalesReport = ({
    userName,
    handleAbout,
    handleProducts,
    handlePromotionalPath,
    handleContact,
    handleLogin,
    dropdownItems,
    openDropdown,
    setOpenDropdown,
    hoverIndex,
    setHoverIndex,
    showMenu,
    setShowMenu,
    dropdownRef,
    menuRef,
    navigate,
}) => {
    const location = useLocation();
    const { username } = location.state || {};
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [fetchedUserName, setUserName] = useState('');
    const [teamManager, setTeamManager] = useState('');
    const [currentUserId, setCurrentUserId] = useState(''); // New state to hold current user ID

    useEffect(() => {
        if (username) {
            const db = getDatabase();
            const usersRef = ref(db, 'users');
            onValue(usersRef, (snapshot) => {
                const users = snapshot.val();
                if (users) {
                    const user = Object.values(users).find(user => user.username === username);
                    setUserName(user ? user.name : 'User not found');
                    setCurrentUserId(user ? user.username : ''); // Set current user ID
                    setTeamManager(user ? `${user.name} (${user.username})` : 'User not found');
                } else {
                    setUserName('No user data available');
                }
            }, (error) => {
                setUserName('Error loading user');
            });
        }
    }, [username]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersRef = ref(getDatabase(), 'users');
                // Removed the limitToFirst(10) to fetch all users matching the criteria
                const usersQuery = query(usersRef, orderByChild('teamManager'), equalTo(teamManager));
    
                const snapshot = await get(usersQuery);
                if (snapshot.exists()) {
                    const fetchedUsers = [];
                    const uniqueUsernames = new Set();
    
                    snapshot.forEach((childSnapshot) => {
                        const userData = childSnapshot.val();
                        const userId = userData.username;
    
                        // Exclude the current user from the fetched users
                        if (userId !== currentUserId && !uniqueUsernames.has(userId)) {
                            uniqueUsernames.add(userId);
                            fetchedUsers.push({
                                id: childSnapshot.key,
                                username: userId,
                                name: userData.name,
                                phone: userData.phone,
                                teamLeader: userData.teamLeader,
                                trainingManager: userData.trainingManager || 'N/A',
                                registrationDate: userData.regDate || 'N/A',
                            });
                        }
                    });
    
                    setUsers(fetchedUsers);
                } else {
                    setError('No users found');
                }
            } catch (err) {
                setError('Failed to fetch users');
            } finally {
                setLoading(false);
            }
        };
    
        if (teamManager) {
            fetchUsers();
        }
    }, [teamManager, currentUserId]); // Include currentUserId in the dependency array
    

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div style={styles.wrapper}>
            <NavTaskBar 
                username={username} 
                userName={fetchedUserName}
                handleAbout={handleAbout}
                handleProducts={handleProducts}
                handlePromotionalPath={handlePromotionalPath}
                handleContact={handleContact}
                handleLogin={handleLogin}
                dropdownItems={dropdownItems}
                openDropdown={openDropdown}
                setOpenDropdown={setOpenDropdown}
                hoverIndex={hoverIndex}
                setHoverIndex={setHoverIndex}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                dropdownRef={dropdownRef}
                menuRef={menuRef}
                navigate={navigate}
            />
            <div style={styles.container}>
                <h2 style={styles.header}>Direct Sales Report</h2>
                <div style={styles.tableContainer}>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.tableHeader}>No</th>
                                <th style={styles.tableHeader}>Customer ID</th>
                                <th style={styles.tableHeader}>Name</th>
                                <th style={styles.tableHeader}>Phone No</th>
                                <th style={styles.tableHeader}>Team Leader</th>
                                <th style={styles.tableHeader}>Training Manager</th>
                                <th style={styles.tableHeader}>Reg. Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? 'rgba(0, 128, 128, 0.7)' : '#E0FFFF' }}>
                                    <td style={styles.tableData}>{index + 1}</td>
                                    <td style={styles.tableData}>{user.username}</td>
                                    <td style={styles.tableData}>{user.name}</td>
                                    <td style={styles.tableData}>{user.phone}</td>
                                    <td style={styles.tableData}>{user.teamLeader}</td>
                                    <td style={styles.tableData}>{user.trainingManager}</td>
                                    <td style={styles.tableData}>{user.registrationDate}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={styles.imageContainer} />
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Ashia. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};
const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh', // Keeps the wrapper at least the full view height
        height: 'auto', // Allow the content to determine height
        background: '#E0FFFF', // Light teal background color
        width: '1620px', // Fixed width for non-responsiveness
    },
    
    imageContainer: {
        width: '100%', // Full width of the viewport
        height: '300px', // Fixed height for the image
        bottom: 0, // Align to the bottom
        left: 0, // Align to the left
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`, // Add a gradient overlay
        backgroundSize: 'cover', // Ensure the image covers the container
        zIndex: -1, // Place it behind other content
    },
    container: {
        padding: '20px',
        backgroundColor: 'rgba(224, 255, 255, 0.7)',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        margin: '20px auto',
        maxWidth: '900px',
        position: 'relative',
        zIndex: 1,
    },
    header: {
        fontSize: '1.5em',
        textAlign: 'center',
        color: 'teal',
    },
    tablesContainer: {
        display: 'flex',
        overflowX: 'auto', // Enable horizontal scrolling
        justifyContent: 'space-between',
        borderRadius: '16px',
    },
    tableWrapper: {
        width: '100%', // Allow table to take full width
        borderRadius: '16px',
        overflowX: 'auto', // Add horizontal scrolling if necessary
    },
    tableHeading: {
        fontSize: '1.2em',
        textAlign: 'center',
        color: 'rgba(224, 255, 255, 0.7)',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        borderRadius: '8px',
        marginTop: '20px',
    },
    tableHeader: {
        backgroundColor: '#008080',
        color: 'white',
        padding: '10px', // Increased padding for better readability
        borderRadius: '8px',
        border: '1px solid #dddddd',
        textAlign: 'left',
    },
    tableData: {
        padding: '10px', // Increased padding for better readability
        borderRadius: '8px',
        textAlign: 'left',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        zIndex:1
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
        zIndex:1
    },
};

export default DirectSalesReport;

