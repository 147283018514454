import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { ref, get, child, onValue } from 'firebase/database'; 
import { database } from './firebaseConfig'; 
import NavTaskBar from './NavTaskBar'; 
import imagePath from './bancsuplex.jpg'; 

function CustomerRegistrationForm() {
    const location = useLocation();
    const navigate = useNavigate();
    const { username } = location.state; 
    const [error, setError] = useState('');
    const [userName, setUserName] = useState(''); 
    const [displayedPlacementTop, setDisplayedPlacementTop] = useState(''); // Display without "SRI"
    const [hiddenPlacementTop, setHiddenPlacementTop] = useState('SRI'); // Store the hidden "SRI" prefix
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false); 

    useEffect(() => {
        if (username) {
            const db = database;
            const usersRef = ref(db, 'users');
            onValue(usersRef, (snapshot) => {
                const users = snapshot.val();
                if (users) {
                    const user = Object.values(users).find(user => user.username === username);
                    if (user) {
                        setUserName(user.name); 
                    } else {
                        setUserName('User not found');
                    }
                } else {
                    setUserName('No user data available');
                }
            }, (error) => {
                console.error('Error fetching data from Firebase:', error);
                setUserName('Error loading user');
            });
        }
    }, [username]);

    const handleSelectManager = () => {
        if (displayedPlacementTop) {
            const fullPlacementTop = hiddenPlacementTop + displayedPlacementTop; // Prepend the hidden "SRI" prefix
            const dbRef = ref(database);

            get(child(dbRef, 'users')).then((snapshot) => {
                if (snapshot.exists()) {
                    const users = snapshot.val();
                    const user = Object.values(users).find(user => user.username === fullPlacementTop);

                    if (user) {
                        navigate('/registration', { state: { username: user.username, userName: user.name } });
                        setError(''); 
                    } else {
                        setError('No user found with the provided Placement Top.');
                    }
                } else {
                    setError('No data found in the database.');
                }
            }).catch((error) => {
                console.error("Error checking placementTop:", error);
                setError('An error occurred while checking the Placement Top.');
            });
        } else {
            setError('Please enter the Placement Top.');
        }
    };

    const handleInputChange = (e) => {
        setDisplayedPlacementTop(e.target.value); // Only display the part without "SRI"
    };

    const handleReset = () => {
        setDisplayedPlacementTop(''); // Reset displayed value
        setError(''); 
    };

    return (
        <div style={styles.wrapper}>
            <NavTaskBar 
                username={username} 
                userName={userName} 
                dropdownItems={[]} 
            />

            <div style={styles.formContainer}>
                <header style={{ textAlign: 'right', marginBottom: '20px' }}>
                    <h2 style={{
                        color: '#008080', // Dark teal text color
                        fontWeight: 'bold',
                        position: 'relative', // Position for the pseudo-element
                        display: 'inline-block', // Keep the border wrapping tightly around the text
                    }}>
                        Customer Registration Form
                        <span style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            color: '#E0FFFF', // Light teal border color
                            zIndex: -1, // Place the border behind the text
                            filter: 'blur(1px)', // Blur effect to create a softer border
                            whiteSpace: 'nowrap', // Prevent line wrapping
                        }}>
                            Customer Registration Form
                        </span>
                    </h2>
                </header>

                <div style={styles.formInnerContainer}>
                    <section style={{ marginBottom: '20px' }}>
                        <h4 style={{ color: '#008080', marginBottom: '10px' }}>
                            Step 1 of 4: Select Team Manager
                        </h4>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <label style={{ marginRight: '20px', color: '#999999' }}>#SRI</label>
                            <input
                                type="text"
                                value={displayedPlacementTop} // Only show the part without "SRI"
                                onChange={handleInputChange}
                                style={{
                                    width: '100%',
                                    maxWidth: '200px',
                                    marginTop: '10px',
                                    padding: '3px',
                                    borderRadius: '8px',
                                    border: '1px solid #008080',
                                    backgroundColor: 'transparent',
                                    fontSize: '20px',
                                }}
                            />
                            <label
                                onClick={handleSelectManager}
                                style={buttonStyles}
                            >
                                Select Team Manager
                            </label>
                            <label
                                onClick={handleReset}
                                style={buttonreset}
                            >
                                Reset
                            </label>
                        </div>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </section>
                </div>
            </div>
            <div style={styles.imageContainer} /> {/* Image container for the background */}
            <footer style={styles.footer}>
                <div style={styles.footerContainer}>
                    <p>© 2024 Suplex Asia PVT (LTD). All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
}

const buttonStyles = {
    padding: '0.5em 1.5em',
    backgroundColor: '#008080',
    color: '#E0FFFF',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    margin: '0.5em',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '20%',
    fontSize: '0.8em',
    textAlign: 'center',
};
const buttonreset = {
    padding: '0.5em 1.5em',
    backgroundColor: '#008080',
    color: '#E0FFFF',
    fontWeight: 'bold',
    border: 'none',
    cursor: 'pointer',
    margin: '0.5em',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    minWidth: '10%',
    fontSize: '0.8em',
    textAlign: 'center',
};

const styles = {
    wrapper: {
        fontFamily: 'Arial, sans-serif',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
        position: 'relative',
        minHeight: '100vh', // Keeps the wrapper at least the full view height
        height: '1000px', // Allow the content to determine height
        background: '#E0FFFF', // Light teal background color
        width: '1620px', // Fixed width for non-responsiveness
    },
    
    imageContainer: {
        width: '100%', // Full width of the wrapper
        height: '300px', // Fixed height for the image
        position: 'absolute', // Position it at the bottom of wrapper
        bottom: 0, // Align to the bottom
        left: 0, // Align to the left
        background: `linear-gradient(to bottom, rgba(224, 255, 254, 0.9), rgba(224, 255, 255, 0.1)), url(${imagePath}) no-repeat center`, // Add a gradient overlay
        backgroundSize: 'cover', // Cover the container
        //zIndex: 1, // Place behind other content
    },
    
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        backgroundColor: 'rgba(0, 77, 77, 0.3)', // Increased transparency
        borderRadius: '25px',
        boxShadow: '0 4px 5px rgba(0, 0, 0, 0.2)', // Softer shadow for a lifted look
        backdropFilter: 'blur(10px)', // Blur to create the frosted glass effect
        WebkitBackdropFilter: 'blur(10px)', // For Safari support
        border: '1px solid rgba(255, 255, 255, 0.3)', // Slightly more visible white border
        width: '90%',
        maxWidth: '10000px',
        margin: '20px auto',
        zIndex: 1, // Bring it above the image
    },
    formInnerContainer: {
        padding: '20px',
        width: '100%',
        backgroundColor: 'rgba(224, 255, 255, 0.7)',
        borderRadius: '25px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        margin: '20px auto',
        maxWidth: '1000px',
        position: 'relative',
    },
    footer: {
        backgroundColor: '#008080',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
    },
    footerContainer: {
        margin: '0 auto',
        width: '80%',
    },
    
};

export default CustomerRegistrationForm;
